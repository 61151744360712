/**
 * Created by Ignacio on 18/6/2018.
 */
import {Component} from '@angular/core';

@Component({
    selector: 'gl-app-loading',
    template: `
        <div class="background-loading">
            <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%);">
                <div class="glosus-loading">
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {
}
