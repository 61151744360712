/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/14/16.
 */
import {PipeTransform, Pipe} from '@angular/core';

import {NumberUtil} from '../../utils/number-util';

import {TranslateService} from '@ngx-translate/core';
import {StringUtil} from '../../utils/string-util';
import {ValueUtil} from '../../utils/value-util';

@Pipe({name: 'localeNumberInput'})
export class LocaleNumberInputPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {
    }

    transform(value: any): string {
        if (!ValueUtil.isEmpty(value) && !StringUtil.isEmpty(String(value))) {
            value = NumberUtil.convertLocaleStringToNumber(this._translateService.currentLang, value);
            if (NumberUtil.isValid(value)) {
                return parseFloat(value).toLocaleString(this._translateService.currentLang);
            } else {
                return value;
            }
        }
    }
}
