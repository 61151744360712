/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 4/13/17.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DownloadLinkComponent} from './download-link.component';
import {PopoverModule} from 'ngx-smart-popover';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        PopoverModule,
        TranslateModule
    ],
    declarations: [
        DownloadLinkComponent
    ],
    exports: [
        DownloadLinkComponent
    ]
})

export class DownloadLinkModule {

}
