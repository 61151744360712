/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/18/17.
 */
import {Component, Input} from '@angular/core';
import {User} from '../../../../models/user';

@Component({
    selector: 'gl-user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {
    @Input() user: User;
    @Input() size = 20;
}
