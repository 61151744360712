/**
 * Util for number variable
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 * @export
 */
export class NumberUtil {

    /**
     * check if number is valid
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValid(num: number) {
        return !isNaN(num);
    }

    /**
     * check if number is valid ratio
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValidRatio(num: number) {
        return !isNaN(num) && num > 0 && num <= 100;
    }

    /**
     * check if number is valid choice
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValidChoice(num: number) {
        return !isNaN(num) && num !== 0 && num !== null;
    }

    static convertLocaleStringToNumber(lang: string, value: any) {
        if (lang === 'de') {
            value = String(value).split('.').join('');
            value = String(value).split(',').join('.');
        } else {
            value = String(value).split(',').join('');
        }
        return value;
    }

    static formatBytes(size: number, locale: string) {
        if (0 === size) {
            return '0 Bytes';
        }
        const baseNumber = 1e3,
            unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            formattedSize = Math.floor(Math.log(size) / Math.log(baseNumber));
        return parseFloat((size / Math.pow(baseNumber, formattedSize)).toFixed(2)).toLocaleString(locale) + ' ' + unit[formattedSize];
    }

}
