import {ValueUtil} from './value-util';
/**
 * Util for string variable
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 *
 * @export
 */
export class StringUtil {
    /**
     * checking if a string is empty, null or undefined
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isEmpty(str: string) {
        return ValueUtil.isEmpty(str) || (!str || 0 === str.length || !str.trim());
    }

    /**
     * checking if a string is blank, null or undefined
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isBlank(str: string) {
        return ValueUtil.isEmpty(str) || (!str || /^\s*$/.test(str) || !str.trim());
    }

    /**
     * checking if a string is valid password
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValidPassword(str: string) {
        const reg = /(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+/;
        return (!StringUtil.isEmpty(str) && reg.test(str) && str.length >= 8);
    }

    static isValidEmail(email: string) {
        // tslint:disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static isValidYear(year: string) {
        const re = /^(19|20)\d{2}$/;
        return re.test(year);
    }

    static isValidLongitude(longitude: string) {
        const re = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/;
        return re.test(longitude);
    }

    static isValidLatitude(latitude: string) {
        const re = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/;
        return re.test(latitude);
    }

    static hasCapitals(password: string) {
        const re = /[A-Z]/;
        return re.test(password);
    }

    static hasNonCapitals(password: string) {
        const re = /[a-z]/;
        return re.test(password);
    }

    static hasNumbers(password: string) {
        const re = /[0-9]/;
        return re.test(password);
    }

    static hasSpecialCharacters(password: string) {
        const re = /([!,%,&,@,#,$,^,*,?,_,~])/;
        return re.test(password);
    }

    static parseStringToNumberArray(string: string) {
        const strArr: string[] = string.split(',');
        const numberArr: number[] = [];
        strArr.forEach(str => numberArr.push(+str));
        return numberArr;
    }

    static parseStringToStringArray(string: string) {
        const strArr: string[] = string.split(',');
        const result: string[] = [];
        strArr.forEach(str => result.push(str));
        return result;
    }
}
