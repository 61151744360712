/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/18/17.
 */
import {NgModule} from '@angular/core';
import {UserIconComponent} from './user-icon.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        UserIconComponent
    ],
    exports: [
        UserIconComponent
    ]
})
export class UserIconModule {

}
