/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {NgModule} from '@angular/core';
import { BackgroundComponent } from './background.component';
import { BackgroundService } from './background.service';
import { PopoverModule } from 'ngx-smart-popover';
import { GLOSUSBasisModule } from '../../basis/glosus-basis.module';

@NgModule({
    imports: [
        GLOSUSBasisModule,
        PopoverModule
    ],
    declarations: [
        BackgroundComponent
    ],
    providers: [
        BackgroundService
    ],
    exports: [
        BackgroundComponent
    ]
})

export class BackgroundModule {
}
