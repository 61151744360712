import { Component, HostListener, HostBinding, ViewChild, ApplicationRef, OnInit } from '@angular/core';
import { OAuthService } from '../oauth/oauth.service';
import { NavbarService } from './navbar.service';
import { User } from '../../models/user';
import { timer } from 'rxjs';
import { ValueUtil } from '../../utils/value-util';
import { BugReportFormComponent } from './bug-report-form/bug-report-form.component';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

/** Render Top and Bottom Navigation Bars */
@Component({
    selector: 'gl-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    @ViewChild(BugReportFormComponent) bugReportForm: BugReportFormComponent;
    @HostBinding('class.show') showNavbar: boolean;

    private _NAVBAR_HEIGHT = 52;
    private _SCREEN_XXS_MAX = 479;
    private _scrollPosition = 0;
    private _enabled: boolean;

    private _user: User;
    private _notificationCount = 0;
    private _request: any;
    private _timerRequest: any;

    @HostListener('window:scroll', ['$event'])
    scrollEvent(event: any) {
        if (this._enabled && (window.innerWidth <= this._SCREEN_XXS_MAX)) {
            this.toggleNavigationBar();
        }
    }

    constructor(private _oauthService: OAuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _navbarService: NavbarService,
        private app: ApplicationRef) {
        this._scrollPosition = document.documentElement.scrollTop;
    }

    ngOnInit() {
        // subscribe of logged in user
        this._oauthService.user$.subscribe(
            user => {
                this._user = user;
                this._notificationCount = 0;
                if (null !== user) {
                    const notificationsTimer = timer(0, 60000);
                    this._timerRequest = notificationsTimer.subscribe(() => {
                        this._request = this._navbarService.getNotificationCount()
                            .subscribe(count => {
                                this._notificationCount = count;
                            });
                    });
                } else {
                    this._navbarService.reset();
                    if (!ValueUtil.isEmpty(this._request)) {
                        this._request.unsubscribe();
                    }
                    if (!ValueUtil.isEmpty(this._timerRequest)) {
                        this._timerRequest.unsubscribe();
                    }
                }
            }
        );

        // Check if the navigation bar is disabled in this route
        this._router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                let currentRoute = this._route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                const navbar = currentRoute.snapshot.data['navbar'];
                this._enabled = navbar !== undefined ? navbar : true;
            });
    }

    showBugReportForm() {
        this.bugReportForm.showModal();
    }

    private toggleNavigationBar() {
        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        if (scrollTop < this._NAVBAR_HEIGHT) {
            return;
        }

        this.showNavbar = (scrollTop > this._scrollPosition) ? false : true;
        this._scrollPosition = scrollTop;

        this.app.tick();
    }
}
