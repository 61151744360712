/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/19/16.
 */
import {throwError} from 'rxjs';
import {ValueUtil} from './value-util';

/**
 * ServerUtil
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 */
export class ServerUtil {
    /**
     * Check if token is invalid
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     * @returns boolean
     */
    static isTokenInvalid(res: any) {
        if (res.status === 401
            && res !== null
            && res.error.error === 'invalid_grant'
            && res.error.error_description.indexOf('invalid')
        ) {
            console.log('Token invalid');
            return true;
        }
        return false;
    }

    /**
     * Check if refresh token is invalid
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     * @returns boolean
     */
    static isRefreshTokenInvalid(res: any) {
        if (res.status === 400
            && res !== null
            && res.error.error === 'invalid_grant'
            && res.error.error_description.indexOf('refresh token')
        ) {
            console.log('Refresh token invalid');
            return true;
        }
        return false;
    }

    /**
     * Check if token is expired
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     * @returns boolean
     */
    static isTokenExpired(res: any) {
        if (res.status === 401
            && res !== null
            && res.error.error === 'invalid_grant'
            && res.error.error_description.indexOf('expire')
        ) {
            console.log('Token expired');
            return true;
        }
        return false;
    }

    /**
     * Check if access denied
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     * @returns boolean
     */
    static isAuthRequired(res: any) {
        if (res.status === 401
            && res !== null
            && res.error.error === 'access_denied'
            && res.error.error_description.indexOf('required')
        ) {
            console.log('oauth2 token required');
            return true;
        }
        return false;
    }

    /**
     * Handle other error by output in console
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     * @returns ErrorObservable
     */
    static handleOtherError(error: any) {
        if (error.error) {
            // handle custom error
            return throwError(error.error);
        }

        // avoid empty response error is shown
        if (!ValueUtil.isEmpty(error) && !ValueUtil.isEmpty(error.currentTarget)) {
            if (0 === error.currentTarget.status) {
                return throwError('');
            }
        }

        // handle server error
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        return throwError(errMsg);
    }

    static handleServerResponse(res: any): any {
        if (res && res.status) {
            if (res.status === 200) {
                return {'data': res.data, 'message': res.message};
            } else if (res.status === 412) {
                return {'data': null, 'message': res.message};
            } else {
                throw throwError(res.message);
            }
        }
        throw throwError('Unknown error');
    }

    static handleUploaderResponse(res: any): any {
        const json = JSON.parse(res);
        if (json && json.status) {
            if (json.status === 200) {
                return {'data': json.data, 'message': json.message};
            }
            if (json.status === 400) {
                throw throwError(json.message);
            }
        }
        throw throwError('Unknown error');
    }
}
