/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/5/16.
 */
import {Injectable} from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild
} from '@angular/router';
import {OAuthService} from '../oauth.service';

/** Badge guard to check if url access is allowed */
@Injectable()
export class BadgeGuard implements CanActivate, CanActivateChild {
    constructor(private _oauthService: OAuthService,
                private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkCompanySubscribeBadge(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    private checkCompanySubscribeBadge(url: string): boolean {
        if (this._oauthService.isLoggedIn) {
            if (this._oauthService.getLoggedInUser().lastLoginCompany.subscribeBadge) {
                return true;
            } else {
                this._router.navigate(['/dashboard']);
                return false;
            }
        }
        this._oauthService.redirectUrl = url;
        this._router.navigate(['/account/sign-in']);
        return false;
    }
}
