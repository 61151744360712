import { Component } from '@angular/core';
import { Cookie } from '../../../../constants/storage';
import { StorageService } from '../../../oauth/storage.service';

@Component({
    selector: 'gl-cookies-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss']
})

/**
 * Render Cookies consent banner
 *
 * @author Ignacio del Barrio <ignacio.del.barrio@glosus.com>
 * @export
 * @class CookiesBannerComponent
 */
export class CookiesBannerComponent {
    private isVisible: boolean;
    // private shouldFadeIn: boolean;
    // private shouldFadeOut: boolean;

    constructor(private _storageService: StorageService) {
    }

    /**
     *
     * Show banner
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     */
    showBanner() {
        this.isVisible = true;
    }

    /**
     * Hide banner and store the user consent
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     */
    hideBanner() {
        this._storageService.setLocalStorage(Cookie.cookieBannerSeen, '1');
        this.isVisible = false;
    }

    visible(): boolean {
        return this.isVisible;
    }
}
