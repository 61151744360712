/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/13/16.
 */
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CATEGORY_COLORS, Chart, Color, SENTIMENT_COLORS} from './chart';
import {NumberUtil} from '../../../../utils/number-util';
import {ValueUtil} from '../../../../utils/value-util';

@Component({
    selector: 'gl-pie-chart',
    templateUrl: './chart.component.html'
})


export class PieChartComponent {
    @Input()
    set chart(chart: Chart) {
        if (!ValueUtil.isEmpty(chart.options)) {
            this._options = Object.assign(this._options, chart.options);
        }
        if (!ValueUtil.isEmpty(chart.legend)) {
            this._legend = chart.legend;
        }
        if (!ValueUtil.isEmpty(chart.dataSets)) {
            this._datasets = chart.dataSets;
        }
        if (!ValueUtil.isEmpty(chart.labels)) {
            this._labels = chart.labels;
        }
    }

    @Input()
    set category(category: boolean) {
        if (category) {
            this._colors = Color.getPieChartColors(CATEGORY_COLORS);
        }
    }

    @Input()
    set sentiment(sentiment: boolean) {
        if (sentiment) {
            this._colors = Color.getPieChartColors(SENTIMENT_COLORS);
        }
    }

    @Input()
    set datasets(datasets: any[]) {
        this._datasets = datasets;
    }

    @Input()
    set labels(labels: string[]) {
        this._labels = labels;
    }

    @Input()
    set legend(legend: boolean) {
        this._legend = legend;
    }

    @Input()
    set colors(colors: any) {
        this._colors = colors;
    }

    @Input()
    set pieChartOptions(options: any) {
        this._options = Object.assign(this._options, options);
    }

    @Output() chartClick: EventEmitter<any> = new EventEmitter();
    @Output() chartHover: EventEmitter<any> = new EventEmitter();

    protected _chartType = 'pie';

    private _colors: any;
    private _legend = false;
    private _datasets: any[];
    private _labels: string[];
    private _options: any = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    const allData = data.datasets[tooltipItem.datasetIndex].data;
                    const tooltipLabel = data.labels[tooltipItem.index];
                    const tooltipData = allData[tooltipItem.index];
                    let total = 0;
                    for (const i in allData) {
                        if (NumberUtil.isValid(allData[i])) {
                            total += Number(allData[i]);
                        }
                    }
                    const tooltipPercentage = Math.round((tooltipData / total) * 100);
                    return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                }
            }
        },
        legend: {
            labels: {
                boxWidth: 12,
                usePointStyle: true,
                // generateLabels: function (chart: any) {
                //     let labels: any[] = [];
                //     for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
                //         chart.data.datasets[0] = PieChartComponent.generateDataSets(chart.data.datasets[0], [
                //             [255, 99, 132],
                //             [54, 162, 235],
                //             [255, 206, 86],
                //             [75, 192, 192],
                //             [153, 102, 255],
                //             [255, 159, 64]]);
                //         let originalLabel = String(chart.data.labels[i]);
                //         labels.push({
                //             text: originalLabel.slice(0, 15) + (originalLabel.length > 15 ? '...' : ''),
                //             fillStyle: chart.data.datasets[0].backgroundColor[i],
                //             strokeStyle: 'rgba(0,0,0,0)',
                //             originalText: originalLabel
                //         });
                //     }
                //     return labels;
                // },
            }
        },
        responsive: true
    };

    chartHovered(event: any) {
        this.chartHover.emit(event);
    }

    chartClicked(event: any) {
        this.chartClick.emit(event);
    }

    // static generateDataSets(datasets: any, colors: any[]) {
    //     datasets.backgroundColor = colors.map((color: number[]) => Color.rgba(color, 0.7));
    //     datasets.borderColor = colors.map(() => '#fff');
    //     datasets.pointBackgroundColor = colors.map((color: number[]) => Color.rgba(color, 1));
    //     datasets.pointBorderColor = colors.map(() => '#fff');
    //     datasets.pointHoverBackgroundColor = colors.map((color: number[]) => Color.rgba(color, 1));
    //     datasets.pointHoverBorderColor = colors.map((color: number[]) => Color.rgba(color, 1));
    //     datasets.hoverBackgroundColor = colors.map((color: number[]) => Color.rgba(color, 1));
    //     datasets.hoverBorderColor = colors.map((color: number[]) => '#fff');
    //     return datasets;
    // }
}
