import { CookieOptions } from 'ngx-cookie';

export class Cookie {
    static isLoggedIn = 'ILI';
    static accessToken = 'T';
    static refreshToken = 'RT';
    static isLoggedInTrue = '1';
    static locale = 'L';
    static keepLogin = 'K';
    static isKeptTrue = '1';
    static cookieBannerSeen = 'CBS';
    // dev - test
    // static options: CookieOptions = {
    //     secure: false
    // };

    // prod
    static neverExpireOptions() {
        const options: CookieOptions = {
            secure: false,
            expires: new Date(
                (new Date()).getFullYear() + 10,
                (new Date()).getMonth(),
                (new Date()).getDate(),
                (new Date()).getHours(),
                (new Date()).getMinutes(),
                (new Date()).getSeconds(),
                (new Date()).getMilliseconds()
            )
        };
        return options;
    }

    static keepLogInOptions() {
        const options: CookieOptions = {
            secure: false,
            expires: new Date(
                (new Date()).getFullYear(),
                (new Date()).getMonth(),
                (new Date()).getDate() + 7,
                (new Date()).getHours(),
                (new Date()).getMinutes(),
                (new Date()).getSeconds(),
                (new Date()).getMilliseconds()
            )
        };
        return options;
    }

    static defaultOptions() {
        const options: CookieOptions = {
            secure: false,
            expires: new Date(
                (new Date()).getFullYear(),
                (new Date()).getMonth(),
                (new Date()).getDate(),
                (new Date()).getHours(),
                (new Date()).getMinutes() + 60,
                (new Date()).getSeconds(),
                (new Date()).getMilliseconds()
            )
        };
        return options;
    }
}

export class LocalStorage {
    static hasHistory = 'HH';
    static hasHistoryInTrue = '1';
}

