/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 4/12/17.
 */
import {Component, Input} from '@angular/core';
@Component({
    selector: 'gl-ell-title',
    templateUrl: './ell-title.component.html',
    styleUrls: ['./ell-title.component.scss']
})
export class EllTitleComponent {
    @Input() title: string;

    private _disable = true;

    mouseEnter(event: any) {
        this._disable = !(event.target.offsetWidth < event.target.scrollWidth);
    }
}
