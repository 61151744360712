/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/16/17.
 */
import {Component, OnInit} from '@angular/core';

import {Notification} from './notification';
import {NotificationListService} from './notification-list.service';
import {FlashMessageService} from '../shared/views/flash-message/flash-message.service';
import {OAuthService} from '../oauth/oauth.service';
import {User} from '../../models/user';

@Component({
    selector: 'gl-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
    private _notifications: Notification[] = [];
    private _isLoading = false;
    private _noMoreEntries = false;
    private _user: User;

    constructor(private _notificationListService: NotificationListService,
                private _oauthService: OAuthService,
                private _flashMessageService: FlashMessageService) {
    }

    ngOnInit() {
        this._oauthService.user$.subscribe(user => this._user = user);
        this._notificationListService.notifications$.subscribe(notifications => {
            this._isLoading = false;
            this._notifications = notifications;
        }, error => {
            this._isLoading = false;
            this._flashMessageService.showErrorMessage(error);
        });

        this._notificationListService.noMoreEntries$.subscribe(noMoreEntries => {
            this._noMoreEntries = noMoreEntries;
        });

        this._isLoading = true;
        this._notificationListService.getNotificationsByCompany();
        this._user = this._oauthService.getLoggedInUser();
    }

    // Called by infinit scroll, deprecated
    onScroll() {
        if (!this._noMoreEntries) {
            this._isLoading = true;
            this._notificationListService.getNextPageNotifications();
        }
    }
}
