import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'src/app/oauth/oauth.service';
import { User } from 'src/models/user';

@Component({
    selector: 'gl-background',
    templateUrl: './background.component.html',
    styleUrls: ['./background.component.scss']
})

/**
 * Render Application Backgrounds and Information Footer
 *
 * @author Ignacio del Barrio <ignacio.del.barrio@glosus.com>
 * @export
 * @class BackgroundComponent
 */
export class BackgroundComponent implements OnInit {
    private _background: any;
    private _showMore: boolean = false;
    private _showInfo: boolean = false;
    private _currentLang: string;
    private _user: User;
    private _currentYear: number = new Date().getFullYear();
    
    // Type: 0 is social, 1 is enviroment and 2 is economic
    private _backgrounds: any[] = [
        {
            name: 'Efficient',
            type: 2,
            // tslint:disable-next-line:max-line-length
            blur: '/assets/background/efficient/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Economic_Impact_SDG_implement_industry_4.0_efficient_businesses_blur.jpg',
            // tslint:disable-next-line:max-line-length
            full: '/assets/background/efficient/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Economic_Impact_SDG_implement_industry_4.0_efficient_businesses.jpg',
            title: 'gen_wallpaper_heading_challenge_implement_industry_4.0_efficient_businesses',
            description: 'gen_wallpaper_paragraph_description_implement_industry_4.0_efficient_businesses',
            relevance: 'gen_wallpaper_paragraph_relevance_implement_industry_4.0_efficient_businesses',
            initiatives: 'gen_wallpaper_paragraph_initiatives_implement_industry_4.0_efficient_businesses',
            sdgs: [4, 8, 12]
        },
        {
            name: 'Forest',
            type: 1,
            blur: '/assets/background/forest/GLOSUS_Sustainability_Education_Forrest_blur.jpg',
            full: '/assets/background/forest/GLOSUS_Sustainability_Education_Forrest.jpg',
            title: 'gen_wallpaper_heading_challenge_keep_forest_areas',
            description: 'gen_wallpaper_paragraph_description_keep_forest_areas',
            relevance: 'gen_wallpaper_paragraph_relevance_keep_forest_areas',
            initiatives: 'gen_wallpaper_paragraph_initiatives_keep_forest_areas',
            sdgs: [2, 3, 6, 12, 13, 15]
        },
        {
            name: 'Water Vortex',
            type: 1,
            blur: '/assets/background/vortex/GLOSUS_Sustainability_Education_Water_Vortex_blur.jpg',
            full: '/assets/background/vortex/GLOSUS_Sustainability_Education_Water_Vortex.jpg',
            title: 'gen_wallpaper_heading_challenge_avoid_gulf_stream_disruption',
            description: 'gen_wallpaper_paragraph_description_avoid_gulf_stream_disruption',
            relevance: 'gen_wallpaper_paragraph_relevance_avoid_gulf_stream_disruption',
            initiatives: 'gen_wallpaper_paragraph_initiatives_avoid_gulf_stream_disruption',
            sdgs: [7, 9, 12, 13, 14, 15]
        },
        {
            name: 'Equal Pay',
            type: 0,
            // tslint:disable-next-line:max-line-length
            blur: '/assets/background/gender/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Social_Impact_SDG_Decent_Work_Equal_Pay_blur.jpg',
            // tslint:disable-next-line:max-line-length
            full: '/assets/background/gender/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Social_Impact_SDG_Decent_Work_Equal_Pay.jpg',
            title: 'gen_wallpaper_heading_challenge_decent_work_equal_pay',
            description: 'gen_wallpaper_paragraph_description_decent_work_equal_pay',
            relevance: 'gen_wallpaper_paragraph_relevance_decent_work_equal_pay',
            initiatives: 'gen_wallpaper_paragraph_initiatives_decent_work_equal_pay',
            sdgs: [1, 4, 5, 8, 10, 16]
        },
];

    constructor(private _route: ActivatedRoute,
        private _router: Router,
        private _oauthService: OAuthService,
        private _translate: TranslateService) {
        this._currentLang = _translate.currentLang;
    }

    ngOnInit(): void {
        this._user = this._oauthService.getLoggedInUser();

        // Set initially a random Background
        let random = Math.floor(Math.random() * Math.floor(this._backgrounds.length));
        this._background = this._backgrounds[random];

        // Switch to user background
        this._oauthService.user$.subscribe(user => {
            this._user = user;
            if (this._user) {
                if (this._user.background_id >= 12) {
                    this._background = this._backgrounds[this._user.background_id - 12];
                } else {
                    this._background = null;
                }
            } else {
                random = Math.floor(Math.random() * Math.floor(this._backgrounds.length));
                this._background = this._backgrounds[random];
            }
        });
    }

    toggleShowMore() {
        this._showMore = !this._showMore;
    }

    toggleShowInfo() {
        this._showInfo = !this._showInfo;
        this._showMore = this._showMore && this._showInfo;
    }

    protected displayFooter() {
        if (this._router.isActive(this._router.createUrlTree(['account/sign-in'], { relativeTo: this._route }), false)
            || this._router.isActive(this._router.createUrlTree(['account/sign-up'], { relativeTo: this._route }), false)
            || this._router.isActive(this._router.createUrlTree(['account/request-password'], { relativeTo: this._route }), false)) {
            return true;
        }
        return false;
    }

}
