/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {Directive, ElementRef, Output, EventEmitter, Input, ContentChild} from '@angular/core';
import {DropdownNotClosableDirective} from './dropdown-not-closable.directive';

@Directive({
    selector: '[glDropdown]',
})
export class DropdownDirective {

    @Input() toggleClick = true;

    @Input() activateOnFocus = false;

    @Output() open = new EventEmitter();

    @Output() close = new EventEmitter();

    @ContentChild(DropdownNotClosableDirective, {static: true}) notClosableZone: DropdownNotClosableDirective;

    constructor(private _elementRef: ElementRef) {
    }

    openDropdown() {
        const element: HTMLElement = this._elementRef.nativeElement;
        element.classList.add('open');
        this.open.emit(undefined);
    }

    closeDropdown() {
        const element: HTMLElement = this._elementRef.nativeElement;
        element.classList.remove('open');
        this.close.emit(undefined);
    }

    isOpened() {
        const element: HTMLElement = this._elementRef.nativeElement;
        return element.classList.contains('open');
    }

    isInClosableZone(element: HTMLElement) {
        if (!this.notClosableZone) {
            return false;
        }
        return this.notClosableZone.contains(element);
    }
}
