/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/5/16.
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from '../oauth.service';
import { OAuthUtil } from '../../../utils/oauth-util';
import { StorageService } from '../storage.service';

/** Auth guard to check if url access is allowed */
@Injectable()
export class UserGuard implements CanActivate {
    constructor(private _oauthService: OAuthService,
        private _storageService: StorageService,
        private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkBusinessUserLogin(state.url);
    }

    private checkBusinessUserLogin(url: string): boolean {
        if (this._oauthService.isLoggedIn || OAuthUtil.isLoggedInInBrowser(this._storageService)) {
            return true;
        }

        this._oauthService.redirectUrl = url;
        this._router.navigate(['/account/sign-in']);
        return false;
    }
}
