/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 6/24/16.
 */
import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from '../../oauth/oauth.service';
import { User } from '../../../models/user';
import { NavbarService } from './../navbar.service';
import { FlashMessageService } from '../../shared/views/flash-message/flash-message.service';
import { StringUtil } from '../../../utils/string-util';
// import { Notification } from '../../notifications/notification';
import { TranslateService, LangChangeEvent } from '../../../../node_modules/@ngx-translate/core';
import { Cookie } from '../../../constants/storage';
import { StorageService } from '../../oauth/storage.service';

/** Render Top Navigation Bar  */
@Component({
    selector: 'gl-top-navbar',
    templateUrl: './top-navbar.component.html',
    styleUrls: ['./top-navbar.component.scss']
})

export class TopNavbarComponent implements OnInit {
    @ViewChild('feedbackTrigger') feedbackTrigger: ElementRef;

    @Input() notificationCount: number;

    @Output() showBugReportForm: EventEmitter<any> = new EventEmitter();

    private _user: User;
    private _companyName: string = null;
    private _isSending = false;
    private _feedback: string = null;
    private _notificationPanelHeight: string;
    private _currentLang: string = null;
    private _notifications: Notification[] = null;

    constructor(private _oauthService: OAuthService,
        private _navbarService: NavbarService,
        private _flashMessageService: FlashMessageService,
        private _translateService: TranslateService,
        private _storageService: StorageService,
        private _router: Router) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this._notificationPanelHeight = (event.target.innerHeight - 150) + 'px';
    }

    ngOnInit() {
        this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this._currentLang = event.lang;
        });
        this._currentLang = this._translateService.currentLang;
        // subscribe of logged in user
        this._oauthService.user$.subscribe(user => this._user = user);
    }

    changeLanguage(locale: string) {
        this._translateService.use(locale);
        this._storageService.set(Cookie.locale, locale);
        this._currentLang = locale;
    }

    /** Show or panel_should_hide advanced-search panel for responsive view */
    search(companyName: string) {
        this._companyName = null;
        if (companyName !== null && companyName !== '') {
            this._router.navigate(['/advanced-search'], { queryParams: { name: companyName } });
        }
    }

    onShowBugReportForm() {
        this.showBugReportForm.emit(null);
    }

    openNotificationPanel() {
        this._navbarService.getDashboardNotification()
            .subscribe(notifications => {
                this.notificationCount = 0;
                this._notifications = notifications;
                this._notificationPanelHeight = (window.innerHeight - 150) + 'px';
            }, error => {
                this._notifications = [];
                this._flashMessageService.showErrorMessage(error);
            });
    }

    /* Deprecated */

    showFeedback() {
        this.feedbackTrigger.nativeElement.click();
    }

    sendFeedback() {
        if (StringUtil.isEmpty(this._feedback)) {
            return;
        }
        this._isSending = true;
        this._navbarService.sendFeedback(this._feedback)
            .subscribe(() => {
                this._flashMessageService.showSuccessMessage('Successfully send feedback');
                this._isSending = false;
                this._feedback = null;
                this.showFeedback();
            }, error => {
                this._isSending = false;
                this._flashMessageService.showErrorMessage(error);
            });
    }
}
