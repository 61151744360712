/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/9/16.
 */

import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found.component';
import { GLOSUSBasisModule } from '../shared/basis/glosus-basis.module';

@NgModule({
    imports: [GLOSUSBasisModule],
    declarations: [PageNotFoundComponent]
})

export class PageNotFoundModule {

}
