/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/5/16.
 */
import {Injectable} from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    Params
} from '@angular/router';
import {OAuthService} from '../oauth.service';

/** Data aggregation guard to check if url access is allowed */
@Injectable()
export class DataAggregationGuard implements CanActivate, CanActivateChild {
    constructor(private _oauthService: OAuthService,
                private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkCompanySubscribeDataAggregation(state.url.split('?')[0], next.queryParams);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    private checkCompanySubscribeDataAggregation(url: string, params: Params): boolean {
        if (this._oauthService.isLoggedIn) {
            if (this._oauthService.getLoggedInUser().lastLoginCompany.subscribeDataAggregation) {
                return true;
            } else {
                this._router.navigate(['/dashboard']);
                return false;
            }
        }
        this._oauthService.redirectUrl = url;
        this._oauthService.queryParams = params;
        this._router.navigate(['/account/sign-in'], {queryParamsHandling: 'preserve'});
        return false;
    }
}
