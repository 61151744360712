/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 4/13/17.
 */
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GLOSUSForm} from '../../basis/glosus-form';
import {StringUtil} from '../../../../utils/string-util';
import {DOWNLOAD_BASE_URL} from '../../../../constants/url';
import {FlashMessageService} from '../../views/flash-message/flash-message.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'gl-download-link',
    templateUrl: './download-link.component.html',
    styleUrls: ['./download-link.component.scss']
})
export class DownloadLinkComponent extends GLOSUSForm {
    @ViewChild('download', { static: true }) download: ElementRef;
    @Input() tip: string;
    @Input() showText = false;
    @Input() position = 'auto left';

    @Output() click: EventEmitter<DownloadLinkComponent> = new EventEmitter<DownloadLinkComponent>();

    constructor(private _flashMessageService: FlashMessageService,
                private _translateService: TranslateService) {
        super();
    }

    realDownload(link: string) {
        if (StringUtil.isEmpty(link)) {
            this._translateService.get('flash_error_download')
                .subscribe(trans => this._flashMessageService.showErrorMessage(trans));
            return;
        }
        this.stopProcessing();
        this.download.nativeElement.href = DOWNLOAD_BASE_URL + link;
        this.download.nativeElement.click();
    }

    showError(error: string) {
        this.stopProcessing();
        this._flashMessageService.showErrorMessage(error);
    }

    protected clickDownload(event: any) {
        event.stopPropagation();
        this.startProcessing();
        this.click.emit(this);
    }

    protected stopPropagation(event: any) {
        event.stopPropagation();
    }

    checkFormValid() {
        return true;
    }
}
