/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 1/18/17.
 */
export class Tag {
    id: string;
    name: string;
    text: string;

    generateTagFromJson(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.text = json.text;
    }
}
