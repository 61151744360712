/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */

export class FlashMessage {
    id: number;
    text: string;
    type: string;

    constructor(text: string, type: string) {
        this.id = Math.floor(Math.random() * 100) + 1;
        this.text = text;
        this.type = type;
    }
}


