import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, tap } from 'rxjs/operators';
import { OAuthService } from './oauth.service';
import { OAuthUtil } from 'src/utils/oauth-util';
import { StorageService } from './storage.service';
import { Cookie } from 'src/constants/storage';
import { TOKEN_API_URL, USER_CLIENT_ID, USER_CLIENT_SECRET } from 'src/constants/url';
import { FlashMessageService } from '../shared/views/flash-message/flash-message.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private _storageService: StorageService,
        private http: HttpClient,
        private authService: OAuthService,
        private flashMessage: FlashMessageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = this.addHeaders(request);

        if (OAuthUtil.isAnonymousAccessTokenExist(this._storageService)) {
            request = this.addToken(request, this._storageService.get(Cookie.accessToken));
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else if (error instanceof HttpErrorResponse && error.status === 500) {
                this.flashMessage
                .showErrorMessage('Oh no! Something bad happened. Please try again later. Thanks.');
                return throwError(error);
            } else {
                return throwError(error);
            }
        }));
    }

    private addHeaders(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Accept-Language': this._storageService.get(Cookie.locale)
                    ? this._storageService.get(Cookie.locale)
                    : navigator.language
            }
        });
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.refreshToken().pipe(
                switchMap((tokens: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(tokens.access_token);
                    return next.handle(this.addToken(request, tokens.access_token));
                }),
                catchError(error => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(null);
                    this.authService.logout();
                    return throwError(error);
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request, token));
                }));
        }
    }

    private refreshToken() {
        const body = JSON.stringify({
            grant_type: 'refresh_token',
            client_id: USER_CLIENT_ID,
            client_secret: USER_CLIENT_SECRET,
            refresh_token: this._storageService.get(Cookie.refreshToken)
        });
        return this.http.post(TOKEN_API_URL, body).pipe(
            tap((tokens: any) => {
                OAuthUtil.saveUserAccessToken(tokens, this._storageService);
            }));
    }
}
