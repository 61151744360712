import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../models/user';
import {OAuthService} from '../../oauth/oauth.service';
import {FlashMessageService} from '../../shared/views/flash-message/flash-message.service';
import {ValueUtil} from '../../../utils/value-util';
import { Company } from '../../../models/company';
import { TranslateService } from '@ngx-translate/core';

/** Render Companies Navbar Item */
@Component({
    selector: 'gl-company-nav-item',
    templateUrl: './company-nav-item.component.html',
    styleUrls: ['./company-nav-item.component.scss', '../navbar.component.scss'],
})

export class CompanyNavItemComponent {
    @Input()
    public get user(): User {
        return this._user;
    }
    public set user(value: User) {
        this._user = value;
        this.reorderCompanies();
    }

    private _user: User;
    private _isSwitching = false;

    private _orderedCompanies: Company[];

    constructor(private _oauthService: OAuthService,
                private _flashMessageService: FlashMessageService,
                private _translate: TranslateService,
                private _router: Router) {

    }

    /** Update user's last login company and redirect to dashboard */
    updateLastLoginCompany(slug: string) {
        if (ValueUtil.isEmpty(this.user) || (
            !ValueUtil.isEmpty(this.user)
            && !ValueUtil.isEmpty(this.user.lastLoginCompany)
            && this.user.lastLoginCompany.slug === slug)
        ) {
            this._router.navigate(['/dashboard']);
        } else {
            this._isSwitching = true;
            this._oauthService.updateUserLastLoginCompany(slug).subscribe(() => {
                this._isSwitching = false;
                this._router.navigate(['/dashboard']);
            }, error => {
                this._flashMessageService.showErrorMessage(error);
            });
        }
    }

    private reorderCompanies() {
        const lang = this._translate.currentLang;
        this._orderedCompanies = this._user.companyList.sort(function (a, b) {
            return a.name.localeCompare(b.name, lang);
        });
    }
}
