/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/27/16.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BUG_API_BASE_URL} from '../../../constants/url';
import {GLOSUSFileUploader} from '../../shared/input/file-picker/glosus-file-uploader';
import {ServerUtil} from '../../../utils/server-util';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BugReportFormService {

    constructor(private _http: HttpClient) {}

    public reportBug(url: string, description: string) {
        const body = JSON.stringify({url: url, description: description});
        return this._http.post(BUG_API_BASE_URL, body).pipe(
            map(res => ServerUtil.handleServerResponse(res).data.id),
            catchError(ServerUtil.handleOtherError)
        );
    }

    public uploadScreenShot(bugId: string, uploader: GLOSUSFileUploader) {
        uploader.uploadSingleFile(BUG_API_BASE_URL + '/' + bugId + '/screen_shot');
    }
}
