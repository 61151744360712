/**
 * Created by dansen on 7/6/16.
 * Updated by ignacio on 3/28/2018
 */
import {Selector} from '../models/selector';

export const BUSINESS_INTERESTS: Selector[] = [
    {
        key: 1,
        value: 'core_account_choice_user_interests_sustainable_investments'
    },
    {
        key: 2,
        value: 'core_account_choice_user_interests_reporting'
    },
    {
        key: 3,
        value: 'core_account_choice_user_interests_sustainable_supply_chain'
    },
    {
        key: 4,
        value: 'core_account_choice_user_interests_human_rights'
    },
    {
        key: 5,
        value: 'core_account_choice_user_interests_animal_welfare'
    },
    {
        key: 6,
        value: 'core_account_choice_user_interests_partnerships_sustainability_goals'
    },
    {
        key: 7,
        value: 'core_account_choice_user_interests_education_sustainability'
    },
    {
        key: 8,
        value: 'core_account_choice_user_interests_sustainable_cities'
    },
    {
        key: 9,
        value: 'core_account_choice_user_interests_sustainable_consumption'
    },
    {
        key: 10,
        value: 'core_account_choice_user_interests_clean_energy'
    },
    {
        key: 11,
        value: 'core_account_choice_user_interests_diversity_equal_opportunity'
    },
    {
        key: 12,
        value: 'core_account_choice_user_interests_philanthropy'
    },
    {
        key: 13,
        value: 'core_account_choice_user_interests_climate_change'
    },
    {
        key: 14,
        value: 'core_account_choice_user_interests_risk_compliance'
    },
    {
        key: 15,
        value: 'core_account_choice_user_interests_innovation_sustainable_solutions'
    },
    {
        key: 16,
        value: 'core_account_choice_user_interests_sustainability_integration'
    }
];
