import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from './spinner/spinner.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { NewlineModule } from '../../pipes/newline.module';
import { ShortDateModule } from '../../pipes/short-date.module';
import { LocaleStringModule } from '../../pipes/locale-string.module';
import { PopoverModule } from 'ngx-smart-popover';
import { ConfirmDeleteModule } from '../input/confirm-delete/confirm-delete.module';
import { EditableDivModule } from '../input/editable-div/editable-div.module';
import { DownloadLinkModule } from '../input/download-link/download-link.module';
import { UserIconModule } from '../views/user-icon/user-icon.module';
import { EllTitleModule } from '../views/ell-title/ell-title.module';
import { ChartModule } from '../views/chart/chart.module';
import { EmployeeService } from '../service/employee.service';

/*
import {PreventParentScrollModule} from '../prevent-parent-scroll/prevent-parent-scroll.module';
import {GLOSUSHttp} from '../../oauth/glosus-http';
*/
/**
 * This module provides access to common modules shared by most features.
 *
 * All modules containing components should import this module.
 * Includes base Angular modules for basic directives, forms, routing and http requests.
 * Also custom modules for loading spinners, date formatting, translation, and other UI elements.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SpinnerModule,
        NewlineModule,
        ShortDateModule,
        LocaleStringModule,
        TranslateModule,
        PopoverModule,
        ConfirmDeleteModule,
        EditableDivModule,
        DownloadLinkModule,
        UserIconModule,
        EllTitleModule,
        ChartModule,
        /*
        PreventParentScrollModule,
        */
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SpinnerComponent,
        TranslateModule,
        NewlineModule,
        ShortDateModule,
        LocaleStringModule,
        PopoverModule,
        ConfirmDeleteModule,
        EditableDivModule,
        DownloadLinkModule,
        UserIconModule,
        EllTitleModule,
        ChartModule,
        /*
        PreventParentScrollModule,
        */
    ],
    providers: [
        EmployeeService,
        /*
        {
            provide: HttpClient,
            useFactory: (backend: XHRBackend, defaultOptions: RequestOptions, router: Router, storageService: StorageService) =>
                new GLOSUSHttp(backend, defaultOptions, router, storageService),
            deps: [XHRBackend, RequestOptions, Router, StorageService]
        }
        */
    ]
})

export class GLOSUSBasisModule {
}
