/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/10/17.
 */
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {GLOSUSForm} from '../../basis/glosus-form';
import {FlashMessageService} from '../../views/flash-message/flash-message.service';

@Component({
    selector: 'gl-confirm-delete',
    templateUrl: './confirm-delete.component.html'
})

export class ConfirmDeleteComponent extends GLOSUSForm {
    @Input() position = 'auto left';
    @Input() message = '';
    @Input() showText = false;

    @Output() confirmDelete: EventEmitter<ConfirmDeleteComponent> = new EventEmitter<ConfirmDeleteComponent>();

    constructor(private _flashMessageService: FlashMessageService) {
        super();
    }

    clickDelete() {
        this.startProcessing();
        this.confirmDelete.emit(this);
    }

    showError(error: string) {
        this._flashMessageService.showErrorMessage(error);
    }

    checkFormValid() {
        return true;
    }
}
