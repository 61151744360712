/**
 * Created by dansen on 7/6/16.
 */
import {Component} from '@angular/core';

@Component({
    selector: 'gl-load-spinner',
    template: `
        <div class="bouncing-loader">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
        </div>
    `,
    styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent {
}
