/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/14/16.
 */
import {PipeTransform, Pipe} from '@angular/core';

import {NumberUtil} from '../../utils/number-util';

import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'localeString'})
export class LocaleStringPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {
    }

    transform(value: any): string {
        if (NumberUtil.isValid(value)) {
            return parseFloat(value).toLocaleString(this._translateService.currentLang);
        } else {
            return value;
        }
    }
}
