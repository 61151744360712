/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/27/16.
 */

import { NgModule } from '@angular/core';

import { GLOSUSBasisModule } from '../../shared/basis/glosus-basis.module';

import { BugReportFormComponent } from './bug-report-form.component';
import { BugReportFormService } from './bug-report-form.service';
import { FilePickerModule } from '../../shared/input/file-picker/file-picker.module';

@NgModule({
    imports: [
        GLOSUSBasisModule,
        FilePickerModule
    ],
    declarations: [
        BugReportFormComponent
    ],
    providers: [BugReportFormService],
    exports: [BugReportFormComponent],
})
export class BugReportFormModule {
}
