/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/19/16.
 */
import {Cookie} from '../constants/storage';
import {HttpHeaders} from '@angular/common/http';
import {StorageService} from '../app/oauth/storage.service';

/** Util handling oauth2 */
export class OAuthUtil {

    /** Check if user is logged in in browser */
    static isLoggedInInBrowser(service: StorageService) {
        const isLoggedIn = service.get(Cookie.isLoggedIn);
        return isLoggedIn === Cookie.isLoggedInTrue;
    }

    /** Check if access token exists*/
    static isAnonymousAccessTokenExist(service: StorageService) {
        const access_token = service.get(Cookie.accessToken);
        return !(access_token === undefined || access_token === null || access_token.length === 0);
    }

    /** Check if user's refresh token exists */
    static isUserRefreshTokenExist(service: StorageService) {
        const refresh_token = service.get(Cookie.refreshToken);
        return !(refresh_token === undefined || refresh_token === null || refresh_token.length === 0);
    }

    /** Save anonymous access token into local storage */
    static saveAnonymousAccessToken(res: any, service: StorageService) {
        if (null !== res.access_token) {
            service.set(Cookie.accessToken, res.access_token);
        }
    }

    /** Save user login status, access token and refresh token into local storage */
    static saveUserAccessToken(res: any, service: StorageService) {
        if (null !== res.access_token && null !== res.refresh_token) {
            service.set(Cookie.accessToken, res.access_token);
            service.set(Cookie.refreshToken, res.refresh_token);
            service.set(Cookie.isLoggedIn, Cookie.isLoggedInTrue);
        }
    }

    /** Generate header with access token */
    static generateDefaultHeaderWithToken(service: StorageService) {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        if (OAuthUtil.isAnonymousAccessTokenExist(service)) {
            headers.append('Authorization', 'Bearer ' + service.get(Cookie.accessToken));
        }
        headers.append('Accept-Language', service.get(Cookie.locale) ? service.get(Cookie.locale) : navigator.language);
        return headers;
    }

    /** Generate Request options after new token requested */
    static regenerateHeaderWithNewToken(options: any, service: StorageService) {
        const sourceOptions = options || {};
        const headers = sourceOptions.headers || new HttpHeaders();
        if (headers.has('Authorization')) {
            headers.delete('Authorization');
        }
        headers.append('Authorization', 'Bearer ' + service.get(Cookie.accessToken));
        headers.append('Accept-Language', service.get(Cookie.locale) ? service.get(Cookie.locale) : navigator.language);
        return sourceOptions;
    }
}
