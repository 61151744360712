/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/20/16.
 */

import {User} from './user';

export enum BusinessUserRole {
    ROLE_ADMIN = 1,
    ROLE_EDITOR = 2,
    ROLE_ASSISTANT = 3,
    ROLE_VIEWER = 4,
    ROLE_AUDITOR = 5
}
export class Relation {
    id: string;
    user: User;
    role: number;

    static castFromJson(json: any) {
        const relation = new Relation();
        return relation.generateRelation(json);
    }

    /** Generate relation instance from json */
    generateRelation(json: any) {
        this.id = json.id;

        const user = new User();
        user.generateBusinessUserListFromJson(json.user);
        this.user = user;
        this.role = json.role;
        return this;
    }
}
