/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/14/16.
 */
import {NgModule} from '@angular/core';

import {ShortDatePipe} from './short-date.pipe';

@NgModule({
    declarations: [
        ShortDatePipe
    ],
    exports: [
        ShortDatePipe
    ]
})
export class ShortDateModule {
}
