/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 11/24/16.
 */
import {NgModule} from '@angular/core';

import {DropdownDirective} from './dropdown.directive';
import {DropdownOpenDirective} from './dropdown-open.directive';
import {DropdownNotClosableDirective} from './dropdown-not-closable.directive';
import {GLOSUSBasisModule} from '../../basis/glosus-basis.module';

@NgModule({
    imports: [
        GLOSUSBasisModule
    ],
    declarations: [
        DropdownDirective,
        DropdownOpenDirective,
        DropdownNotClosableDirective
    ],
    exports: [
        DropdownDirective,
        DropdownOpenDirective,
        DropdownNotClosableDirective
    ]
})

export class DropDownModule {

}
