import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar.component';
import { GLOSUSBasisModule } from '../shared/basis/glosus-basis.module';
import { NavbarService } from './navbar.service';
import { BugReportFormModule } from './bug-report-form/bug-report-form.module';
import { DropDownModule } from '../shared/views/dropdown/dropdown.module';
import { NotificationListModule } from '../notifications/notification-list.module';
import { UserNavItemComponent } from './user-nav-item/user-nav-item.component';
import { CompanyNavItemComponent } from './company-nav-item/company-nav-item.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { ServicesNavItemComponent } from './services-nav-item/services-nav-item.component';
import { BottomNavbarComponent } from './bottom-navbar/bottom-navbar.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';

@NgModule({
    imports: [
        GLOSUSBasisModule,
        BugReportFormModule,
        NotificationListModule,
        NgSlimScrollModule,
        DropDownModule,
        RouterModule,
    ],
    declarations: [
        NavbarComponent,
        CompanyNavItemComponent,
        UserNavItemComponent,
        ServicesNavItemComponent,
        TopNavbarComponent,
        BottomNavbarComponent
    ],
    providers: [
        {
            provide: SLIMSCROLL_DEFAULTS,
            useValue: {
                barOpacity: '0.4',
                barWidth: '8',
                barMargin: '0px 3px',
                gridOpacity: '0.2',
                gridWidth: '8',
                gridMargin: '0px 3px',
                alwaysVisible: true,
                visibleTimeout: 1
            }
        },
        NavbarService
    ],
    exports: [NavbarComponent],
})
export class NavbarModule {
}
