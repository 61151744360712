import {Component, Input} from '@angular/core';
import {User} from '../../../models/user';
import {OAuthService} from '../../oauth/oauth.service';

/** Render User Profile Navbar Item */
@Component({
    selector: 'gl-user-nav-item',
    templateUrl: './user-nav-item.component.html',
    styleUrls: ['./user-nav-item.component.scss', '../navbar.component.scss'],
})

export class UserNavItemComponent {
    @Input() user: User;

    constructor(private _oauthService: OAuthService) {
    }

    /**
     * Log out from app and navigate to sign in page
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    logout() {
        this._oauthService.logout();
    }
}
