/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/16/17.
 */
export class Notification {
    id: string;
    objectId: number;
    type: number;
    status: number;
    count: number;
    sender: string;
    senderPicture: string;
    recipientCompany: string;
    recipientCompanyPicture: string;
    time: string;
    isSenderAsCompany: boolean;
    shortcut: string;

    generateNotificationFromJson(json: any) {
        this.id = json.id;
        this.objectId = json.objectId;
        this.type = json.type;
        this.status = json.status;
        this.count = json.count;
        this.sender = json.sender;
        this.senderPicture = json.senderPicture;
        this.recipientCompany = json.recipientCompany;
        this.recipientCompanyPicture = json.recipientCompanyPicture;
        this.isSenderAsCompany = json.isSenderAsCompany;
        this.time = json.time;
        this.shortcut = json.shortcut;
    }

    get isInit() {
        return this.status === 0;
    }

    get isShown() {
        return this.status === 1;
    }

    get isRead() {
        return this.status === 2;
    }

    get isAssignSuggestion() {
        return this.type === 0;
    }

    get isOwnSuggestion() {
        return this.type === 1;
    }

    get isCommentSuggestion() {
        return this.type === 2;
    }

    get isSuggestionDraft() {
        return this.type === 3;
    }

    get link() {
        if (null !== this.objectId && undefined !== this.objectId) {
            switch (this.type) {
                case 0:
                case 1:
                case 2:
                case 3:
                    return ['/suggestions', this.objectId];
            }
        }
        return ['/'];
    }
}
