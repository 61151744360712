/**
 * Created by ignacio on 20/8/18.
 */
import { Background } from '../models/background';

export const BACKGROUNDS: any[] = [
    {
        id: 1,
        value: '#FCFCFC',
        active: false
    },
    {
        id: 2,
        value: '#ECF3FA',
        active: false
    },
    {
        id: 3,
        value: '#EEF8F6',
        active: false
    },
    {
        id: 4,
        value: '#F1F9F3',
        active: false
    },
    {
        id: 5,
        value: '#EEF1F9',
        active: false
    },
    {
        id: 6,
        value: '#F0F8FA',
        active: false
    },
    {
        id: 7,
        value: '#F2F9ED',
        active: false
    },
    {
        id: 8,
        value: '#FDF5F5',
        active: false
    },
    {
        id: 9,
        value: '#F2F1F9',
        active: false
    },
    {
        id: 10,
        value: '#F5F7F9',
        active: false
    },
    {
        id: 11,
        value: '#F5F9EB',
        active: false
    },
    {
        id: 12,
        value: '#F6EDE6',
        active: false
    },
    // {
    //     id: 13,
    //     value: '#F9F1F8',
    //     active: false
    // },
    // {
    //     id: 14,
    //     value: '#FDEFF7',
    //     active: false
    // },
    // {
    //     id: 15,
    //     value: '#FEF8E6',
    //     active: false
    // },
    // {
    //     id: 16,
    //     value: '#FCF3EA',
    //     active: false
    // },
    {
        id: 13,
        name: 'Efficient',
        type: 2,
        // tslint:disable-next-line:max-line-length
        blur: '/assets/background/efficient/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Economic_Impact_SDG_implement_industry_4.0_efficient_businesses_blur.jpg',
        // tslint:disable-next-line:max-line-length
        full: '/assets/background/efficient/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Economic_Impact_SDG_implement_industry_4.0_efficient_businesses.jpg',
        title: 'gen_wallpaper_heading_challenge_implement_industry_4.0_efficient_businesses',
        description: 'gen_wallpaper_paragraph_description_implement_industry_4.0_efficient_businesses',
        relevance: 'gen_wallpaper_paragraph_relevance_implement_industry_4.0_efficient_businesses',
        initiatives: 'gen_wallpaper_paragraph_initiatives_implement_industry_4.0_efficient_businesses',
        sdgs: [4, 8, 12]
    },
    {
        id: 14,
        name: 'Forest',
        type: 1,
        blur: '/assets/background/forest/GLOSUS_Sustainability_Education_Forrest_blur.jpg',
        full: '/assets/background/forest/GLOSUS_Sustainability_Education_Forrest.jpg',
        title: 'gen_wallpaper_heading_challenge_keep_forest_areas',
        description: 'gen_wallpaper_paragraph_description_keep_forest_areas',
        relevance: 'gen_wallpaper_paragraph_relevance_keep_forest_areas',
        initiatives: 'gen_wallpaper_paragraph_initiatives_keep_forest_areas',
        sdgs: [2, 3, 6, 12, 13, 15]
    },
    {
        id: 15,
        name: 'Water Vortex',
        type: 1,
        blur: '/assets/background/vortex/GLOSUS_Sustainability_Education_Water_Vortex_blur.jpg',
        full: '/assets/background/vortex/GLOSUS_Sustainability_Education_Water_Vortex.jpg',
        title: 'gen_wallpaper_heading_challenge_avoid_gulf_stream_disruption',
        description: 'gen_wallpaper_paragraph_description_avoid_gulf_stream_disruption',
        relevance: 'gen_wallpaper_paragraph_relevance_avoid_gulf_stream_disruption',
        initiatives: 'gen_wallpaper_paragraph_initiatives_avoid_gulf_stream_disruption',
        sdgs: [7, 9, 12, 13, 14, 15]
    },
    {
        id: 16,
        name: 'Equal Pay',
        type: 0,
        // tslint:disable-next-line:max-line-length
        blur: '/assets/background/gender/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Social_Impact_SDG_Decent_Work_Equal_Pay_blur.jpg',
        // tslint:disable-next-line:max-line-length
        full: '/assets/background/gender/Glosus_Sustainability_CSR_ESG_Management_Software_Data_Science_AI_Education_Social_Impact_SDG_Decent_Work_Equal_Pay.jpg',
        title: 'gen_wallpaper_heading_challenge_decent_work_equal_pay',
        description: 'gen_wallpaper_paragraph_description_decent_work_equal_pay',
        relevance: 'gen_wallpaper_paragraph_relevance_decent_work_equal_pay',
        initiatives: 'gen_wallpaper_paragraph_initiatives_decent_work_equal_pay',
        sdgs: [1, 4, 5, 8, 10, 16]
    },
];
