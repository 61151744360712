/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/27/16.
 */
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';

import {BugReportFormService} from './bug-report-form.service';
import {GLOSUSModalForm} from '../../shared/basis/glosus-modal-form';
import {StringUtil} from '../../../utils/string-util';
import {FlashMessageService} from '../../shared/views/flash-message/flash-message.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'gl-bug-report-form',
    templateUrl: './bug-report-form.component.html'
})

export class BugReportFormComponent extends GLOSUSModalForm implements OnInit {
    protected formErrors: any = {
        'description': '',
        'url': ''
    };
    protected validationMessages: any = {
        'description': {
            'required': ''
        },
        'url': {
            'required': ''
        }
    };

    private description: string;
    private url: string;

    constructor(private _bugReportFormService: BugReportFormService,
                private _flashMessageService: FlashMessageService,
                private _translateService: TranslateService,
                private _formBuilder: UntypedFormBuilder) {
        super(true);
    }

    ngOnInit() {
        super.initFileUploader(() => {
            this._translateService.get('flash_thanks_feedback').subscribe(trans => {
                this._flashMessageService.showSuccessMessage(trans);
            });
            this.hideModal();
        });
        this.buildForm();

        // get translation
        this._translateService.get('uni_validation_mandatory_field_blank').subscribe(trans => {
            this.validationMessages.description.required = trans;
            this.validationMessages.url.required = trans;
        });
    }

    buildForm(): void {
        this.form = this._formBuilder.group({
            'description': [this.description,
                [
                    Validators.required,
                ]
            ],
            'url': [this.url,
                [
                    Validators.required,
                ]
            ]
        });
        super.buildForm();
    }

    /**
     * Show update logo basis in modal
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    public showModal() {
        super.showModal(null);
        this.url = null;
        this.description = null;
        this.filePicker.clearQueue();
    }

    /**
     * report bug
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     */
    public reportBug() {
        if (!this.checkFormValid()) {
            return;
        }
        this.startProcessing();
        this._bugReportFormService.reportBug(this.url, this.description)
            .subscribe((bugId: string) => {
                if (this.filePicker.hasFiles()) {
                    this._bugReportFormService.uploadScreenShot(bugId, this.filePicker.uploader);
                } else {
                    this._translateService.get('flash_thanks_feedback').subscribe(trans => {
                        this._flashMessageService.showSuccessMessage(trans);
                    });
                    this.hideModal();
                }
            }, error => {
                this.showFormError(error);
            });
    }

    checkFormValid(): boolean {
        if (StringUtil.isEmpty(this.description) && StringUtil.isEmpty(this.url)) {
            this._translateService.get('validation_fields_invalid').subscribe(trans => this.showFormError(trans));
            return false;
        }
        return true;
    }
}
