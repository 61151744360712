import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from '../../../models/user';

/** Render Services Navbar Item */
@Component({
    selector: 'gl-services-nav-item',
    templateUrl: './services-nav-item.component.html',
    styleUrls: ['./services-nav-item.component.scss', '../navbar.component.scss'],
})

export class ServicesNavItemComponent implements OnInit {
    @Input() user: User;

    private DEFAULT = -1;
    private PROFILE = 1;
    private BLOG = 2;
    private BADGE = 3;
    private MESSAGE = 4;
    private DATA = 5;
    private MY_GROUP = 6;
    private ADMIN_GROUP = 7;
    private PROMOTE = 8;
    private MCD = 9;

    private _currentService: number = this.DEFAULT;

    constructor(private _route: ActivatedRoute,
                private _router: Router) {
    }

    ngOnInit(): void {
        this._router.events.subscribe(() => this.checkRoute());
    }

    private checkRoute() {
        this._currentService = this.DEFAULT;
        if (this._router.isActive(this._router.createUrlTree(['profile'], {relativeTo: this._route}), false)) {
            this._currentService = this.PROFILE;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/groups'], {relativeTo: this._route}), false)) {
            this._currentService = this.MY_GROUP;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/admin-groups'], {relativeTo: this._route}), false)) {
            this._currentService = this.ADMIN_GROUP;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/badge'], {relativeTo: this._route}), false)) {
            this._currentService = this.BADGE;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/data-aggregation'], {relativeTo: this._route}), false)) {
            this._currentService = this.DATA;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/blog'], {relativeTo: this._route}), false)) {
            this._currentService = this.BLOG;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/branches'], {relativeTo: this._route}), false)) {
            this._currentService = this.PROMOTE;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/suggestions'], {relativeTo: this._route}), false)) {
            this._currentService = this.MESSAGE;
            return;
        }
        if (this._router.isActive(this._router.createUrlTree(['/mcd'], {relativeTo: this._route}), false)) {
            this._currentService = this.MCD;
            return;
        }
    }
}
