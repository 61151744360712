/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/20/16.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap, map, catchError, share } from 'rxjs/operators';

import { Relation } from '../../../models/relation';
import { EMPLOYEES_API_BASE_URL } from '../../../constants/url';
import { ServerUtil } from '../../../utils/server-util';
import { ValueUtil } from '../../../utils/value-util';
import { StringUtil } from '../../../utils/string-util';
import { OAuthService } from 'src/app/oauth/oauth.service';

/** EmployeeService */
@Injectable()
export class EmployeeService {
    // employees in memory cache
    private _employees: Relation[];
    private _employees$: Subject<Relation[]>;

    // company slug of last api request
    private _lastRequestSlug: string = null;
    // observable of get employees request
    private _getEmployeesObservable: Observable<any>;

    constructor(private _http: HttpClient,
        private _oauthService: OAuthService) {
        this._employees = [];
        this._employees$ = <Subject<Relation[]>>new Subject();
    }

    /** Get observable of relations */
    get employees$() {
        return this._employees$.asObservable();
    }

    /** Get all company employees by company slug via API or via memory
     * Avoid duplicate get request */
    getEmployeesByCompany() {
        if (!StringUtil.isEmpty(this._lastRequestSlug) && this._lastRequestSlug === this._oauthService.getSlug()) {
            this._employees$.next(this._employees);
        }
        if (ValueUtil.isEmpty(this._getEmployeesObservable)) {
            this._getEmployeesObservable = this.doGetEmployeesByCompany().pipe(share());
        }
        this._getEmployeesObservable.subscribe(
            res => {
                const relations: Relation[] = [];
                for (const relationJson of res.data.relations) {
                    relations.push(Relation.castFromJson(relationJson));
                }
                this._employees = relations;
                this._lastRequestSlug = this._oauthService.getSlug();
                this._employees$.next(this._employees);
            },
            error => this._employees$.error(error));
    }

    updateEmployee(employee: Relation) {
        this._employees.forEach((t, i) => {
            if (t.id === employee.id) {
                this._employees[i] = employee;
            }
        });
        this._employees$.next(this._employees);
    }

    deleteEmployee(employeeId: string) {
        this._employees.forEach((t, i) => {
            if (t.id === employeeId) {
                this._employees.splice(i, 1);
            }
        });
        this._employees$.next(this._employees);
    }

    addEmployee(employee: Relation) {
        this._employees.push(employee);
        this._employees$.next(this._employees);
    }

    /** Trigger API to get employees of company */
    private doGetEmployeesByCompany() {
        return this._http.get(EMPLOYEES_API_BASE_URL + '/all').pipe(
            tap(() => this._getEmployeesObservable = null),
            map(res => ServerUtil.handleServerResponse(res)),
            catchError(ServerUtil.handleOtherError)
        );
    }
}
