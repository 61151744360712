/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/17/17.
 */
import {Component, Input} from '@angular/core';
import {Notification} from './notification';
import {NotificationListService} from './notification-list.service';

@Component({
    selector: 'gl-notification-row',
    templateUrl: './notification-row.component.html',
    styleUrls: ['./notification-list.component.scss']
})

export class NotificationRowComponent {
    @Input() notification: Notification;

    constructor(private _notificationListService: NotificationListService) {
    }

    readNotification() {
        this._notificationListService.readNotification(this.notification.id).subscribe();
    }
}
