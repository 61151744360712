/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/2/16.
 */
export class Chart {
    labels: string[] = [];
    dataSets: any[] = [];
    options: any;
    type: number;
    title: string;
    legend: boolean;

    static castFromJson(json: any) {
        const chart = new Chart();
        return chart.generateChartFromJson(json);
    }

    generateChartFromJson(json: any) {
        this.title = json.title;
        this.labels = json.labels;
        // this.dataSets = json.dataSets;
        this.dataSets = json.data_sets; // TODO: Find out why this was changed
        this.options = json.options;
        this.type = json.type;
        this.legend = json.legend;
        return this;
    }


    get isLineChart() {
        return this.type === 0;
    }

    get isPieChart() {
        return this.type === 1;
    }

    get isBarChart() {
        return this.type === 2;
    }
}


export const CATEGORY_COLORS: any[] = [
    [102, 51, 153],
    [66, 179, 80],
    [0, 105, 179],
    [235, 122, 61]
];

export const CATEGORY_TOTAL_COLORS: any[] = [
    [255, 99, 132],
    [102, 51, 153],
    [66, 179, 80],
    [0, 105, 179]
];

export const SENTIMENT_COLORS: any[] = [
    [76, 175, 80],
    [255, 181, 62],
    [244, 67, 54],
];

export class Color {
    static getPieChartColors(colors: Array<number>[]): any {
        return [{
            backgroundColor: colors.map((color: number[]) => this.rgba(color, 0.7)),
            borderColor: colors.map(() => '#fff'),
            pointBackgroundColor: colors.map((color: number[]) => this.rgba(color, 1)),
            pointBorderColor: colors.map(() => '#fff'),
            pointHoverBackgroundColor: colors.map((color: number[]) => this.rgba(color, 1)),
            pointHoverBorderColor: colors.map((color: number[]) => this.rgba(color, 1)),
            hoverBackgroundColor: colors.map((color: number[]) => this.rgba(color, 1)),
            hoverBorderColor: colors.map((color: number[]) => '#fff')
        }];
    }

    static getBarColors(colors: Array<number>[]): any {
        return [{
            backgroundColor: colors.map((color: number[]) => this.rgba(color, 0.7)),
            borderColor: colors.map(() => '#fff'),
            hoverBackgroundColor: colors.map((color: number[]) => this.rgba(color, 1)),
            hoverBorderColor: colors.map((color: number[]) => '#fff')
        }];
    }

    static getLineColors(colors: Array<number>[]): any {
        return colors.map((color: number[]) => {
            return {
                backgroundColor: this.rgba(color, 0.2),
                borderColor: this.rgba(color, 1),
                hoverBackgroundColor: this.rgba(color, 0.6),
                hoverBorderColor: this.rgba(color, 1)
            };
        });
    }

    static rgba(colour: Array<number>, alpha: number): string {
        return 'rgba(' + colour.concat(alpha).join(',') + ')';
    }
}
