/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 4/12/17.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopoverModule} from 'ngx-smart-popover';
import {EllTitleComponent} from './ell-title.component';

@NgModule({
    imports: [
        CommonModule,
        PopoverModule
    ],
    declarations: [
        EllTitleComponent
    ],
    exports: [
        EllTitleComponent
    ]
})
export class EllTitleModule {

}
