/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/14/16.
 */
import {NgModule} from '@angular/core';

import {LocaleStringPipe} from './locale-string.pipe';
import {LocaleNumberInputPipe} from './locale-number-input.pipe';

@NgModule({
    declarations: [
        LocaleStringPipe,
        LocaleNumberInputPipe
    ],
    exports: [
        LocaleStringPipe,
        LocaleNumberInputPipe
    ]
})
export class LocaleStringModule {
}
