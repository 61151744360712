/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 3/29/17.
 */
import {NgModule} from '@angular/core';
import {NgChartsModule} from 'ng2-charts';

import {BarChartComponent} from './bar-chart.component';
import {LineChartComponent} from './line-chart.component';
import {PieChartComponent} from './pie-chart.component';

@NgModule({
    imports: [
        NgChartsModule
    ],
    declarations: [
        BarChartComponent,
        LineChartComponent,
        PieChartComponent
    ],
    exports: [
        BarChartComponent,
        LineChartComponent,
        PieChartComponent
    ]
})
export class ChartModule {

}
