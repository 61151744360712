/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/10/17.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ConfirmDeleteComponent} from './confirm-delete.component';
import {TranslateModule} from '@ngx-translate/core';
import {PopoverModule} from 'ngx-smart-popover';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PopoverModule
    ],
    declarations: [
        ConfirmDeleteComponent
    ],
    exports: [
        ConfirmDeleteComponent
    ]
})

export class ConfirmDeleteModule {

}
