import { UntypedFormGroup } from '@angular/forms';
import { ViewChild, Directive } from '@angular/core';

import { FilePickerComponent } from '../input/file-picker/file-picker.component';
import { ServerUtil } from '../../../utils/server-util';

/**
 * The GLOSUSForm is the base form template used to create all form components.
 * Modals are used for dialog boxes, confirmation messages, or other content that can be called up.
 *
 * This class provides the minimum requirements of a Modal Form in the GLOSUS Application.
 * Tracks state changes and the validity of form controls.
 * Displays validation errors to users and enable/disable form controls.
 * Provides file uploading functionality with success/error handling.
 * Shows/hides Modals with or without animation effects
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 */
@Directive()
export abstract class GLOSUSModalForm {
    @ViewChild(FilePickerComponent, {static: true}) filePicker: FilePickerComponent;

    protected isVisible: boolean;
    protected shouldFadeIn: boolean;
    protected shouldFadeOut: boolean;
    protected isProcessing = false;

    protected form: UntypedFormGroup;
    protected formErrors = {};
    protected validationMessages = {};

    private _hasPicker = false;

    private _error: string;
    protected get error() {
        return this._error;
    }

    constructor(hasPicker?: boolean) {
        this._hasPicker = hasPicker;
    }

    abstract checkFormValid(): boolean;

    initFileUploader(fileUploadSuccess: FileUploadSuccessCallback) {
        if (this._hasPicker) {
            // register for complete uploading items
            this.filePicker.onUploadFinish = (item: any, response: any, status: any) => {
                this.onUploadComplete(response, status, fileUploadSuccess);
            };
        }
    }

    /** Show basis in modal */
    showModal(t: any) {
        this._error = null;
        this.isVisible = true;
        this.shouldFadeIn = true;
        this.shouldFadeOut = false;
        this.isProcessing = false;
        this.buildForm();
    }

    /** Hide modal */
    hideModal() {
        this._error = null;
        this.shouldFadeIn = false;
        this.shouldFadeOut = true;
        this.isProcessing = false;
        if (this._hasPicker && null !== this.filePicker && undefined !== this.filePicker) {
            this.filePicker.clearQueue();
        }
        const self = this;
        setTimeout(function () {
            self.isVisible = false;
        }, 500);
    }

    /** Start processing basis */
    startProcessing() {
        this._error = null;
        this.isProcessing = true;
    }

    /** Stop processing basis */
    stopProcessing() {
        this.isProcessing = false;

        if (this._hasPicker && null !== this.filePicker && undefined !== this.filePicker) {
            this.filePicker.clearQueue();
        }
    }

    clearError() {
        this._error = null;
    }

    showFileUploadError() {
        this.showFormError('Update failed, please try again');
    }

    showFormError(error: any): void {
        if (typeof error === 'string') {
            this._error = error;
        } else if (typeof error === 'object' && error.error) {
            this._error = error.error;
        } else {
            this._error = 'unknown error';
        }
        this.stopProcessing();
    }

    buildForm(): void {
        this.form.valueChanges.subscribe(data => this.onValueChanged(data));
        this.onValueChanged(); // (re)set validation messages now
    }

    onValueChanged(data?: any) {
        this._error = null;
        if (!this.form) {
            return;
        }
        const form = this.form;

        for (const field in this.formErrors) {
            if (this.formErrors.hasOwnProperty(field)) {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = form.get(field);

                if (control && control.dirty && !control.valid) {
                    const messages = this.validationMessages[field];
                    for (const key in control.errors) {
                        if (this.formErrors.hasOwnProperty(key)) {
                            this.formErrors[field] += messages[key] + '\n';
                        }
                    }
                }
            }
        }
    }

    onUploadComplete(response: any, status: any, fileUploadSuccess: FileUploadSuccessCallback) {
        if (200 === status) {
            fileUploadSuccess(ServerUtil.handleUploaderResponse(response).data);
            if (this.filePicker.single) {
                this.stopProcessing();
            } else if (!this.filePicker.hasFiles()) {
                this.stopProcessing();
            }
        } else {
            if (status === 400 && response._body) {
                this.showFormError(response._body);
            } else {
                this.showFileUploadError();
            }
        }
    }

    onClickOutside(event: Event) {
        const target = event.target as HTMLDivElement;
        if (target.className === 'gl-modal') {
            this.hideModal();
        }
    }
}

interface FileUploadSuccessCallback {
    // tslint:disable-next-line:callable-types
    (response: any): any;
}
