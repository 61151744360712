/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 11/21/16.
 */
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CATEGORY_COLORS, Chart, Color, SENTIMENT_COLORS} from './chart';
import {ValueUtil} from '../../../../utils/value-util';

@Component({
    selector: 'gl-bar-chart',
    templateUrl: './chart.component.html'
})

export class BarChartComponent {
    @Input()
    set chart(chart: Chart) {
        if (!ValueUtil.isEmpty(chart.options)) {
            this._options = Object.assign(this._options, chart.options);
        }
        if (!ValueUtil.isEmpty(chart.legend)) {
            this._legend = chart.legend;
        }
        if (!ValueUtil.isEmpty(chart.dataSets)) {
            this._datasets = chart.dataSets;
        }
        if (!ValueUtil.isEmpty(chart.labels)) {
            this._labels = chart.labels;
        }
    }

    @Input()
    set category(category: boolean) {
        if (category) {
            this._colors = Color.getBarColors(CATEGORY_COLORS);
        }
    }

    @Input()
    set sentiment(sentiment: boolean) {
        if (sentiment) {
            this._colors = Color.getBarColors(SENTIMENT_COLORS);
        }
    }

    @Input()
    set datasets(datasets: any[]) {
        this._datasets = datasets;
    }

    @Input()
    set labels(labels: string[]) {
        this._labels = labels;
    }

    @Input()
    set legend(legend: boolean) {
        this._legend = legend;
    }

    @Input()
    set colors(colors: any) {
        this._colors = colors;
    }


    @Input()
    set barChartOptions(options: any) {
        this._options = Object.assign(this._options, options);
    }

    @Output() chartClick: EventEmitter<any> = new EventEmitter();
    @Output() chartHover: EventEmitter<any> = new EventEmitter();

    protected _chartType = 'bar';

    private _colors: any;
    private _legend = false;
    private _datasets: any[];
    private _labels: string[];
    private _options: any = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        legend: {
            labels: {
                boxWidth: 12,
                usePointStyle: true,
            }
        },
        responsive: true
    };

    chartHovered(event: any) {
        this.chartHover.emit(event);
    }

    chartClicked(event: any) {
        this.chartClick.emit(event);
    }
}
