/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/15/16.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {API_BASE_URL, USER_API_BASE_URL} from '../../constants/url';
import {ServerUtil} from '../../utils/server-util';
import {Observable} from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';
import {Notification} from '../notifications/notification';

@Injectable()
export class NavbarService {
    private _getNotificationCountObservable: Observable<any>;
    private _getDashboardNotificationObservable: Observable<any>;

    constructor(private _http: HttpClient) {
        this._getNotificationCountObservable = null;
        this._getDashboardNotificationObservable = null;
    }

    sendFeedback(feedback: string) {
        const body = JSON.stringify({content: feedback});
        return this._http.post(API_BASE_URL + 'web/feedback', body).pipe(
            map(res => ServerUtil.handleServerResponse(res)),
            catchError(ServerUtil.handleOtherError)
        );
    }

    reset() {
        this._getNotificationCountObservable = null;
        this._getDashboardNotificationObservable = null;
    }

    getNotificationCount() {
        if (null === this._getNotificationCountObservable) {
            this._getNotificationCountObservable = this.doGetNotificationCount().pipe(share());
        }
        return this._getNotificationCountObservable;
    }

    getDashboardNotification() {
        if (null === this._getDashboardNotificationObservable) {
            this._getDashboardNotificationObservable = this.doGetDashboardNotification().pipe(share());
        }
        return this._getDashboardNotificationObservable;
    }

    private doGetNotificationCount() {
        return this._http.get(USER_API_BASE_URL + '/notifications/count').pipe(
            map(res => {
                this._getNotificationCountObservable = null;
                return ServerUtil.handleServerResponse(res).data.count;
            }),
            catchError(error => {
                this._getNotificationCountObservable = null;
                return ServerUtil.handleOtherError(error);
            })
        );
    }

    private doGetDashboardNotification() {
        return this._http.get(USER_API_BASE_URL + '/notifications/dashboard').pipe(
            map(res => {
                this._getDashboardNotificationObservable = null;
                const data = ServerUtil.handleServerResponse(res).data;
                const notifications: Notification[] = [];
                if (null !== data.notifications && undefined !== data.notifications) {
                    for (const notificationJson of data.notifications) {
                        if (null !== notificationJson && undefined !== notificationJson) {
                            const notification = new Notification();
                            notification.generateNotificationFromJson(notificationJson);
                            notifications.push(notification);
                        }
                    }
                }
                return notifications;
            }),
            catchError(error => {
                this._getDashboardNotificationObservable = null;
                return ServerUtil.handleOtherError(error);
            })
        );
    }
}
