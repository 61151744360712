/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/28/16.
 */
import { NgModule } from '@angular/core';

import { FilePickerComponent } from './file-picker.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FileUploadModule,
    ],
    declarations: [
        FilePickerComponent
    ],
    exports: [
        FileUploadModule,
        FilePickerComponent
    ]
})

export class FilePickerModule {

}
