/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {Directive, ElementRef, OnDestroy, Host, HostListener} from '@angular/core';
import {DropdownDirective} from './dropdown.directive';

@Directive({
    selector: '[glDropdownOpen]'
})

export class DropdownOpenDirective implements OnDestroy {
    /**
     * This hack is needed for dropdown not to open and instantly closed
     */
    private _openedByFocus = false;

    @HostListener('click')
    openDropdown() {
        if (this._dropdown.activateOnFocus && this._openedByFocus) {
            this._openedByFocus = false;
            return;
        }

        if (this._dropdown.isOpened() && this._dropdown.toggleClick) {
            this._dropdown.closeDropdown();
            document.removeEventListener('click', this._closeDropdownOnOutsideClick);
        } else {
            this._dropdown.openDropdown();
            document.addEventListener('click', this._closeDropdownOnOutsideClick, true);
        }
    }

    @HostListener('keydown', ['$event'])
    dropdownKeydown(event: KeyboardEvent) {
        if (event.keyCode === 40) { // down
            this.openDropdown();
        }
    }

    @HostListener('focus')
    onFocus() {
        if (!this._dropdown.activateOnFocus) {
            return;
        }
        this._openedByFocus = true;
        this._dropdown.openDropdown();
        document.addEventListener('click', this._closeDropdownOnOutsideClick, true);
    }

    @HostListener('blur', ['$event'])
    onBlur(event: FocusEvent) {
        if (!this._dropdown.activateOnFocus) {
            return;
        }
        if (event.relatedTarget &&
            !this._dropdown.isInClosableZone(<HTMLElement> event.relatedTarget) &&
            event.relatedTarget !== this._elementRef.nativeElement) {

            this._dropdown.closeDropdown();
            document.removeEventListener('click', this._closeDropdownOnOutsideClick);
        }
    }

    constructor(@Host() private _dropdown: DropdownDirective,
                private _elementRef: ElementRef) {
    }

    ngOnDestroy() {
        document.removeEventListener('click', this._closeDropdownOnOutsideClick);
    }

    private _closeDropdownOnOutsideClick = (event: MouseEvent) => this.close(event);

    private close(event: Event) {
        if (!this._dropdown.isInClosableZone(<HTMLElement> event.target)
            && event.target !== this._elementRef.nativeElement
            && !this._elementRef.nativeElement.contains(event.target)) {
            this._dropdown.closeDropdown();
            document.removeEventListener('click', this._closeDropdownOnOutsideClick);
        }
    }
}
