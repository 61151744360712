import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {OAuthService} from '../../oauth/oauth.service';
import {User} from '../../../models/user';
import {NavbarService} from './../navbar.service';
import {FlashMessageService} from '../../shared/views/flash-message/flash-message.service';

/** Render Bottom Navigation Bar */
@Component({
    selector: 'gl-bottom-navbar',
    templateUrl: './bottom-navbar.component.html',
    styleUrls: ['./bottom-navbar.component.scss']
})

export class BottomNavbarComponent implements OnInit {
    @Input()  notificationCount: number;
    @Output() showBugReportForm: EventEmitter<any> = new EventEmitter();

    private _user: User;

    constructor(private _oauthService: OAuthService,
                private _navbarService: NavbarService,
                private _flashMessageService: FlashMessageService) {
    }

    ngOnInit() {
        // subscribe of logged in user
        this._oauthService.user$.subscribe(user => this._user = user);
    }

    OnShowBugReportForm() {
        this.showBugReportForm.emit(null);
    }
}
