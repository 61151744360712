/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

import {FlashMessage} from './flash-message';


@Injectable()
export class FlashMessageService {
    private _message$: Subject<FlashMessage>;

    constructor(private _router: Router) {
        this._message$ = <Subject<FlashMessage>> new Subject();
    }

    get message$() {
        return this._message$.asObservable();
    }

    showSuccessMessage(text: string) {
        const message = new FlashMessage(text, 'success');
        this._message$.next(message);
    }

    showWarnMessage(text: string) {
        const message = new FlashMessage(text, 'warn');
        this._message$.next(message);
    }

    showErrorMessage(text: string) {
        const message = new FlashMessage(text, 'error');
        this._message$.next(message);
    }

    showFormErrorMessage(error: any) {
        const message = new FlashMessage(error.messsage, 'error');
        this._message$.next(message);
    }

    redirectToDashboard() {
        this._router.navigate(['/dashboard']);
    }

    redirectTo(path: string) {
        this._router.navigate([path]);
    }
}

