/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 3/22/17.
 */
import {StringUtil} from './string-util';
/**
 * Util for date variable
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 *
 * @export
 */
export class DateUtil {
    /**
     * checking if a string is valid date
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValidDate(str: string) {
        return DateUtil.strToDate(str) !== null;
    }

    /**
     * Check if a string is valid datetime
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     *
     */
    static isValidDateTime(str: string) {
        const reg = /^(([012][0-9])|(3[01]))\.(0[1-9]|1\d|2\d|3[01])\.(19|20)\d{2}\s+([0-1][0-9]|2[0-3])\:([0-5][0-9])$/;
        return (!StringUtil.isEmpty(str) && reg.test(str) && str.length >= 8);
    }

    static strToDate(str: string) {
        if (StringUtil.isEmpty(str)) {
            return null;
        }
        // format: dd.mm.yyyy
        function pad(x: any) {
            return ((('' + x).length === 2) ? '' : '0') + x;
        }

        const m = str.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
        const d = (m) ? new Date(+m[3], +m[2] - 1, +m[1]) : null;
        const matchesPadded = (d && (str === [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')));
        const matchesNonPadded = (d && (str === [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('.')));
        return (matchesPadded || matchesNonPadded) ? d : null;
    }

    static strToDateTime(str: string) {
        if (StringUtil.isEmpty(str)) {
            return null;
        }
        // format: dd.mm.yyyy
        function pad(x: any) {
            return ((('' + x).length === 2) ? '' : '0') + x;
        }

        const m = str.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})\s+([0-1][0-9]|2[0-3])\:([0-5][0-9])$/);
        const d = (m) ? new Date(+m[3], +m[2] - 1, +m[1], +m[4], +m[5]) : null;
        // tslint:disable-next-line
        let matchesPadded = (d && (str === [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.') + ' ' + pad(d.getHours()) + ':' + pad(d.getMinutes())));
        // tslint:disable-next-line
        let matchesNonPadded = (d && (str === [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('.') + ' ' + d.getHours() + ':' + d.getMinutes()));
        return (matchesPadded || matchesNonPadded) ? d : null;
    }

    static monthStrToDate(str: string) {
        if (StringUtil.isEmpty(str)) {
            return null;
        }
        // format: dd.mm.yyyy
        function pad(x: any) {
            return ((('' + x).length === 2) ? '' : '0') + x;
        }

        const m = str.match(/^(\d{1,2})\.(\d{4})$/);
        const d = (m) ? new Date(+m[2], +m[1] - 1, 1) : null;
        const matchesPadded = (d && (str === [pad(d.getMonth() + 1), d.getFullYear()].join('.')));
        const matchesNonPadded = (d && (str === [d.getMonth() + 1, d.getFullYear()].join('.')));
        return (matchesPadded || matchesNonPadded) ? d : null;
    }

    static dateToStr(date: Date) {
        const mm = date.getMonth() + 1; // getMonth() is zero-based
        const dd = date.getDate();
        return '' + ((dd < 10) ? ('0' + dd) : dd) + '.' + ((mm < 10) ? ('0' + mm) : mm) + '.' + date.getFullYear();
    }

    static dateToMonthStr(date: Date) {
        const mm = date.getMonth() + 1; // getMonth() is zero-based
        return '' + ((mm < 10) ? ('0' + mm) : mm) + '.' + date.getFullYear();
    }

    static olderThan18(dob: string) {
        const date = DateUtil.strToDate(dob);
        if (null === date) {
            return true;
        }
        const today = new Date();
        let age = today.getFullYear() - date.getFullYear();
        const m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        return age >= 18;
    }

    static isGreaterThanToday(str: string) {
        const date = DateUtil.strToDate(str);
        if (null === date) {
            return true;
        }
        return date.getTime() >= new Date().getTime();
    }

    static getToday() {
        const today = new Date();
        const mm = today.getMonth() + 1; // getMonth() is zero-based
        const dd = today.getDate();

        return '' + ((dd < 10) ? ('0' + dd) : dd) + '.' + ((mm < 10) ? ('0' + mm) : mm) + '.' + today.getFullYear();
    }

    static get18Year() {
        const today = new Date();
        return today.getFullYear() - 18;
    }

    static getTodayMonth() {
        const today = new Date();
        return today.getMonth() + 1;
    }

    static getTodayDay() {
        const today = new Date();
        return today.getDate();
    }

    static getTodayYear() {
        const today = new Date();
        return today.getFullYear();
    }

    static getNextMonth(date: Date) {
        if (date.getMonth() === 11) {
            return new Date(date.getFullYear() + 1, 0, 1);
        } else {
            return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
    }

    static getPreviousMonth(date: Date) {
        if (date.getMonth() === 0) {
            return new Date(date.getFullYear() - 1, 11, 1);
        } else {
            return new Date(date.getFullYear(), date.getMonth() - 1, 1);
        }
    }
}
