/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { CookiesBannerComponent } from './cookie-banner.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        CookiesBannerComponent
    ],
    providers: [
    ],
    exports: [
        CookiesBannerComponent
    ]
})

export class CookiesBannerModule {
}
