/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/16/17.
 */
import {NgModule} from '@angular/core';

import {GLOSUSBasisModule} from '../shared/basis/glosus-basis.module';
import {NotificationListComponent} from './notification-list.component';
import {NotificationListService} from './notification-list.service';
// import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NotificationRowComponent} from './notification-row.component';

@NgModule({
    imports: [
        GLOSUSBasisModule,
        // InfiniteScrollModule
    ],
    declarations: [
        NotificationListComponent,
        NotificationRowComponent
    ],
    providers: [
        NotificationListService
    ],
    exports: [
        NotificationRowComponent
    ]
})

export class NotificationListModule {
}
