/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 12/14/16.
 */
import {PipeTransform, Pipe} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {StringUtil} from '../../utils/string-util';
import {DateUtil} from '../../utils/date-util';

@Pipe({name: 'shortDate'})
export class ShortDatePipe implements PipeTransform {
    private _todayLabel = '';
    private _yesterdayLabel = '';

    constructor(private _translateService: TranslateService) {
        this._translateService.get('label_today').subscribe(trans => this._todayLabel = trans);
        this._translateService.get('label_yesterday').subscribe(trans => this._yesterdayLabel = trans);
    }

    transform(value: string): string {
        const parsedDate = DateUtil.strToDateTime(value);
        if (null === parsedDate) {
            return value;
        }
        const d = parsedDate.getDate();
        const m = parsedDate.getMonth() + 1;
        const y = parsedDate.getFullYear();
        const h = parsedDate.getHours();
        const mm = parsedDate.getMinutes();

        const today = new Date();
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        today.setHours(0, 0, 0, 0);
        yesterday.setHours(0, 0, 0, 0);

        if (parsedDate.getTime() >= today.getTime() && !StringUtil.isBlank(this._todayLabel)) {
            return this._todayLabel + ' ' + (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
        } else if (parsedDate.getTime() >= yesterday.getTime() && !StringUtil.isBlank(this._yesterdayLabel)) {
            return this._yesterdayLabel + ' ' + (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
        }

        return '' + (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y + ' '
            + (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
    }
}
