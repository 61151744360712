/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/12/16.
 */
import {Component, OnInit} from '@angular/core';
import {FlashMessage} from './flash-message';
import {FlashMessageService} from './flash-message.service';
import {ValueUtil} from '../../../../utils/value-util';

@Component( {
    selector: 'gl-flash-messages',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.scss']
})

export class FlashMessageComponent implements OnInit {
    protected messages: FlashMessage[] = [];

    constructor(private _flashMessageService: FlashMessageService) {
    }

    ngOnInit() {
        this._flashMessageService.message$.subscribe(message => {
            if (!ValueUtil.isEmpty(message) && !ValueUtil.isEmpty(message.text) && message.text.length > 0) {
                this.showMessage(message);
            }
        });
    }

    showMessage(message: FlashMessage) {
        this.messages.push(message);
        const self = this;
        setTimeout(function () {
            self.hideMessage(message);
        }, 5000);
    }

    hideMessage(message: FlashMessage) {
        this.messages.forEach((t, i) => {
            if (t.text === message.text && t.id === message.id) {
                this.messages.splice(i, 1);
            }
        });
    }
}
