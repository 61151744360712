/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 6/6/17.
 */
import {Injectable} from '@angular/core';
import {Cookie} from '../../constants/storage';
import {CookieService} from 'ngx-cookie';

export abstract class StorageService {
    abstract get(key: string): string;
    abstract getLocalStorage(key: string): string;

    abstract set(key: string, value: string): void;
    abstract setLocalStorage(key: string, value: string): void;

    abstract remove(key: string): void;
    abstract removeLocalStorage(key: string): void;

    abstract clear(): void;
}

@Injectable()
export class BrowserStorageService extends StorageService {
    private _store = {};
    private _isCookieAvailable = false;
    private _isStorageAvailable = false;

    constructor(private _cookieService: CookieService) {
        super();
        // use cookie in first option
        this._isCookieAvailable = (navigator.cookieEnabled) ? true : false;

        // if (!this._isCookieAvailable) {
        this._isStorageAvailable = this.checkIsStorageAvailable();
        if (!this._isStorageAvailable) {
            console.warn('Local storage is not available.');
        }
        // }
    }

    get(key: string) {
        if (this._isCookieAvailable) {
            return this._cookieService.get(key);
        } else {
            return this._store[key];
        }
    }

    getLocalStorage(key: string) {
        if (this._isStorageAvailable) {
            return localStorage.getItem(key);
        } else {
            return this._store[key];
        }
    }

    set(key: string, value: string) {
        if (this._isCookieAvailable) {
            if ((this._isStorageAvailable) && (localStorage.getItem(Cookie.keepLogin) === Cookie.isKeptTrue)) {
                this._cookieService.put(key, value, Cookie.keepLogInOptions());
            } else if (key === Cookie.refreshToken || key === Cookie.isLoggedIn) {
                this._cookieService.put(key, value, Cookie.neverExpireOptions());
            } else {
                this._cookieService.put(key, value, Cookie.defaultOptions());
            }
        } else {
            this._store[key] = value;
        }
    }
    setLocalStorage(key: string, value: string) {
        if (this._isStorageAvailable) {
            localStorage.setItem(key, value);
        } else {
            this._store[key] = value;
        }
    }

    remove(key: string) {
        if (this._isCookieAvailable) {
            this._cookieService.remove(key);
        } else {
            delete this._store[key];
        }
    }

    removeLocalStorage(key: string) {
        if (this._isStorageAvailable) {
            localStorage.removeItem(key);
        } else {
            delete this._store[key];
        }
    }

    clear() {
        if (this._isCookieAvailable) {
            this._cookieService.remove(Cookie.accessToken);
            this._cookieService.remove(Cookie.refreshToken);
            this._cookieService.remove(Cookie.isLoggedIn);
        } else {
            this._store = {};
        }
    }

    private checkIsStorageAvailable() {
        try {
            const key = Math.random().toString(36).substring(7);
            localStorage.setItem(key, key);
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    }

    // private isCookieEnabled() {
    //     let cookieEnabled = navigator.cookieEnabled;
    //
    //     if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    //         this._cookieService.put('testcookie', '-1');
    //         cookieEnabled = this._cookieService.get('testcookie') !== '-1';
    //         this._cookieService.remove('testcookie');
    //     }
    //     return cookieEnabled;
    // }
}
