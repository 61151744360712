/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/27/17.
 */
import {Directive, forwardRef, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
    selector: 'div[glContentEditable]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditableDivDirective),
            multi: true
        }
    ]
})
export class EditableDivDirective implements ControlValueAccessor {
    private _onTouchedCallback: () => void;
    private _onChangeCallback: (_: any) => void;

    constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    }

    @HostListener('keyup', ['$event'])
    keyup(event: any) {
        this.onChange();
    }

    writeValue(val: any) {
        if (!val) {
            val = '';
        }
        this._renderer.setProperty(this._elRef.nativeElement, 'innerText', val);
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedCallback = fn;
    }

    onChange() {
        if (this._onChangeCallback) {
            this._onChangeCallback(this._elRef.nativeElement.innerText);
        }
    }
}
