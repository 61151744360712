/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 7/26/16.
 */
import {Pipe, PipeTransform} from '@angular/core';

/**
 * NewlinePipe
 *
 * Transform newline in php to br
 */
@Pipe({name: 'nl2br'})
export class NewlinePipe implements PipeTransform {
    transform(value: any): string {
        if ((typeof value) !== 'string') {
            return value;
        }
        if (null === value || undefined === value || 'undefined' === value) {
            return '';
        }
        return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
}
