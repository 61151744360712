/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/9/16.
 */
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalStorage } from '../../constants/storage';
import { StorageService } from '../oauth/storage.service';

@Component({
    selector: 'gl-404',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})

export class PageNotFoundComponent {

    constructor(private _location: Location,
        private _storageService: StorageService,
        private _router: Router) {

    }

    goBack() {
        if (this._storageService.get(LocalStorage.hasHistory) === LocalStorage.hasHistoryInTrue) {
            this._location.back();
        } else {
            this._router.navigate(['/']);
        }
    }
}
