/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/28/16.
 */
import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { GLOSUSFileUploader } from './glosus-file-uploader';
import { StorageService } from '../../../oauth/storage.service';

@Component({
    selector: 'gl-file-picker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.scss']
})

export class FilePickerComponent implements OnInit {
    @ViewChild('file') file: ElementRef;
    @Input() size = 5242880; // 5MB
    @Input() type = '';
    @Input() single = true;
    @Input() buttonOnly = false;

    public uploader: GLOSUSFileUploader;
    private _error: string;

    constructor(private _storageService: StorageService) {

    }

    ngOnInit() {
        this.uploader = new GLOSUSFileUploader(this.size, this.type, this._storageService);

        // register for complete uploading items
        this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
            this.onUploadFinish(item, response, status);
        };

        this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
            this.handleError(filter, options);
        };

        this.uploader.onAfterAddingFile = (fileItem: any) => {
            this._error = null;
            // only allow one file in the queue
            if (this.single && this.uploader.queue.length > 1) {
                this.uploader.clearQueue();
                this.uploader.addToQueue([fileItem._file]);
            }
            this.onAddFileSuccess(fileItem);
        };
    }

    public hasFiles(): boolean {
        return this.uploader.queue.length > 0;
    }

    public clearQueue() {
        this.uploader.clearQueue();
        this._error = null;
        this.file.nativeElement.value = null;
    }

    public onUploadFinish(item: any, response: any, status: any): any {
        return { item, response, status };
    }

    public onAddFileSuccess(fileItem: any): any {
        return { fileItem };
    }

    public getHtmlAccept() {
        switch (this.type) {
            case 'image':
                return 'image/jpeg,image/png';
            case 'pdf':
                return 'application/pdf';
            case 'attachment':
                return 'application/pdf,' +
                    'text/csv,' +
                    'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
                    'image/jpeg,image/png,' +
                    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
                    'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,' +
                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
        }

        return '*';
    }

    private handleError(filter: any, options: any) {
        this.file.nativeElement.value = null;
        this.clearQueue();
        if (filter.name === 'mimeType') {
            this._error = 'File type is not allowed';
        } else if (filter.name === 'fileSize') {
            this._error = 'File is too big';
        } else {
            this._error = 'Unknown error';
        }
    }
}
