/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/15/16.
 */
import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[glDropdownNotClosable]'
})
export class DropdownNotClosableDirective {

    @Input() dropdownNotClosable: boolean;

    constructor(private elementRef: ElementRef) {
    }

    contains(element: HTMLElement) {
        if (this.dropdownNotClosable === false) {
            return false;
        }

        const thisElement: HTMLElement = this.elementRef.nativeElement;
        return thisElement.contains(element);
    }
}
