import { UntypedFormGroup } from '@angular/forms';
import { ViewChild, Input, Directive } from '@angular/core';
import {FilePickerComponent} from '../input/file-picker/file-picker.component';
import { ServerUtil } from '../../../utils/server-util';

/**
 * The GLOSUSForm is the base form template used to create all form components.
 * Forms are the mainstay of business applications.
 * You use forms to log in, submit a help request, place an order, book a flight, schedule a meeting,
 * and perform countless other data-entry tasks.
 *
 * This class provides the minimum requirements of a Form in the GLOSUS Application.
 * Tracks state changes and the validity of form controls.
 * Displays validation errors to users and enable/disable form controls.
 * Provides file uploading functionality with success/error handling.
 *
 * @author Dansen Zhou <dansen.zhou@glosus.com>
 */
@Directive()
export abstract class GLOSUSForm {
    @ViewChild(FilePickerComponent, {static: true}) filePicker: FilePickerComponent;

    @Input() isProcessing = false;
    protected form: UntypedFormGroup;
    protected formErrors = {};
    protected validationMessages = {};

    private _error: string;
    protected get error() {
        return this._error;
    }

    private _hasPicker = false;

    constructor(hasPicker?: boolean) {
        this._hasPicker = hasPicker;
    }

    abstract checkFormValid(): boolean;

    initFileUploader(fileUploadSuccess: FileUploadSuccessCallback) {
        if (this._hasPicker) {
            // register for complete uploading items
            this.filePicker.onUploadFinish = (item: any, response: any, status: any) => {
                this.onUploadComplete(response, status, fileUploadSuccess);
            };
        }
    }

    /** Start processing basis */
    startProcessing() {
        this._error = null;
        this.isProcessing = true;
    }

    /** Stop processing basis */
    stopProcessing() {
        this.isProcessing = false;
    }

    showFileUploadError() {
        this.showFormError('Update failed, please try again');
    }

    showFormError(error: any): void {
        if (typeof error === 'string') {
            this._error = error;
        } else if (typeof error === 'object' && error.error) {
            this._error = error.error;
        } else {
            this._error = 'unknown error';
        }
        this.stopProcessing();
    }

    buildForm(): void {
        this.form.valueChanges.subscribe(data => this.onValueChanged(data));
        this.onValueChanged(); // (re)set validation messages now
    }

    onValueChanged(data?: any) {
        this._error = null;
        if (!this.form) {
            return;
        }
        const form = this.form;

        for (const field in this.formErrors) {
            if (this.formErrors.hasOwnProperty(field)) {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = form.get(field);

                if (control && control.dirty && !control.valid) {
                    const messages = this.validationMessages[field];
                    for (const key in control.errors) {
                        if (this.formErrors.hasOwnProperty(key)) {
                            this.formErrors[field] += messages[key] + '\n';
                        }
                    }
                }
            }
        }
    }

    onUploadComplete(response: any, status: any, fileUploadSuccess: FileUploadSuccessCallback) {
        if (200 === status) {
            fileUploadSuccess(ServerUtil.handleUploaderResponse(response).data);
            if (this.filePicker.single) {
                this.stopProcessing();
            } else if (!this.filePicker.hasFiles()) {
                this.stopProcessing();
            }
        } else {
            if (status === 400 && response._body) {
                this.showFormError(response._body);
            } else {
                this.showFileUploadError();
            }
        }
    }
}

interface FileUploadSuccessCallback {
    // tslint:disable-next-line:callable-types
    (response: any): any;
}
