/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/27/17.
 */
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {EditableDivDirective} from './editable-div.directive';
@NgModule({
    imports: [
        FormsModule,
        CommonModule
    ],
    declarations: [
        EditableDivDirective
    ],
    exports: [
        EditableDivDirective
    ]
})

export class EditableDivModule {

}
