/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 2/16/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Notification} from './notification';
import {Subject, Observable} from 'rxjs';
import {USER_API_BASE_URL} from '../../constants/url';
import {ServerUtil} from '../../utils/server-util';
import { catchError, map, share } from 'rxjs/operators';

@Injectable()
export class NotificationListService {
    // notifications in memory cache
    private _notifications: Notification[];
    private _notifications$: Subject<Notification[]>;

    private _noMoreEntries$: Subject<boolean>;

    // observable of get notifications request
    private _getNotificationsObservable: Observable<any>;
    private _readNotificationObservable: Observable<any>;

    private _page = 0;

    private PAGE_LIMIT = 20;

    constructor(private _http: HttpClient) {
        this._notifications = [];
        this._notifications$ = <Subject<Notification[]>>new Subject();
        this._noMoreEntries$ = <Subject<boolean>>new Subject();
        this._getNotificationsObservable = null;
        this._readNotificationObservable = null;
        this._page = 0;
    }

    /** Get notifications observable */
    get notifications$() {
        return this._notifications$.asObservable();
    }


    get noMoreEntries$() {
        return this._noMoreEntries$.asObservable();
    }

    readNotification(id: string) {
        if (null === this._readNotificationObservable) {
            this._readNotificationObservable = this.doReadDashboardNotification(id).pipe(share());
        }
        return this._readNotificationObservable;
    }

    /**
     * Get notifications
     *
     * @author Dansen Zhou <dansen.zhou@glosus.com>
     */
    getNotificationsByCompany() {
        if (null === this._getNotificationsObservable) {
            this._page = 0;
            this._getNotificationsObservable = this.doGetNotificationsByCompany().pipe(share());
        } else {
            return;
        }
        this._getNotificationsObservable
            .subscribe(body => {
                this._notifications = this.castNotifications(body);
                this._notifications$.next(this._notifications);
            }, error => {
                this._notifications$.error(error);
            });
    }

    getNextPageNotifications() {
        if (null === this._getNotificationsObservable) {
            this._getNotificationsObservable = this.doGetNotificationsByCompany().pipe(share());
        } else {
            return;
        }
        this._getNotificationsObservable
            .subscribe(body => {
                this._notifications = this._notifications.concat(this._notifications = this.castNotifications(body));
                this._notifications$.next(this._notifications);
            }, error => {
                this._notifications$.error(error);
            });
    }

    /** Trigger API to get notifications of company */
    private doGetNotificationsByCompany() {
        let params: HttpParams = new HttpParams();
        params = params.append('page', this._page.toString());
        return this._http.get(USER_API_BASE_URL + '/notifications', {params: params}).pipe(
            map(res => {
                this._page += 1;
                return ServerUtil.handleServerResponse(res).data;
            }),
            catchError(error => {
                this._getNotificationsObservable = null;
                return ServerUtil.handleOtherError(error);
            })
        );
    }

    private castNotifications(body: any) {
        const notifications: Notification[] = [];
        if (null !== body.notifications || undefined !== body.notifications) {
            body.notifications.length < this.PAGE_LIMIT ? this._noMoreEntries$.next(true) : this._noMoreEntries$.next(false);
            for (const notificationJson of body.notifications) {
                const notification = new Notification();
                notification.generateNotificationFromJson(notificationJson);
                notifications.push(notification);
            }
        }

        this._getNotificationsObservable = null;
        return notifications;
    }

    private doReadDashboardNotification(id: string) {
        return this._http.put(USER_API_BASE_URL + '/notifications/' + id + '/read', null).pipe(
            map(res => {
                this._readNotificationObservable = null;
                return ServerUtil.handleServerResponse(res).data;
            }),
            catchError(error => {
                this._readNotificationObservable = null;
                return ServerUtil.handleOtherError(error);
            })
        );
    }
}
